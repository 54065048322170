import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'axle-redirect-wrapper',
  template: ''
})
export class AxleRedirectWrapperComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    const queryParams = this.route.snapshot.queryParams;
    const targetPath = this.route.snapshot.data['targetPath'];

    if (id && targetPath) {
      const resolvedPath = targetPath.replace(':id', id);
      this.router.navigate([resolvedPath], { queryParams });
    }
  }
}
