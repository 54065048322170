import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';
import { filter, switchMap } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  defaultRequestOptions,
  jsonDefaultRequestOptions
} from '../../contants/http.constants';
import {
  handleHttpResponse,
  handlePublicHttpResponse
} from '../../functions/http.functions';
import { AuthenticationStore } from '../../state/authentication/authentication.state';
import {
  DeactivateUser,
  EmailValidationResponse,
  IntegrationSignup,
  OrgInfoResponse,
  OutlookSignup,
  PossibleAttendee,
  PromoteUser,
  UpdateOrgResponse,
  UserInfoResponse
} from './user.models';

const baseUserUrl = `${environment.backendLink}/user`;
const baseOrgUrl = `${environment.backendLink}/organizations`;
const getUserInfoUrl = `${baseUserUrl}/info`;
const updateUserUrl = `${baseUserUrl}/update`;
const validateUserEmailUrl = `${baseUserUrl}/validateUserEmail`;
const getOrgInfoUrl = (orgId: string) => `${baseOrgUrl}/${orgId}`;
const updateOrgUrl = `${baseOrgUrl}/update`;
const getOrgInfoWithEmailUrl = `${baseOrgUrl}/email`;
const promoteUserUrl = `${baseUserUrl}/promoteUser`;
const deactivateUserUrl = `${baseUserUrl}/deactivateUser`;
const refreshVerifiedEmailStatusUrl = `${baseUserUrl}/emailValidation`;
const googleSignupUrl = `${environment.backendLink}/integration/google/signup`;
const outlookSignupUrl = `${environment.backendLink}/integration/outlook/signup`;
const zoomSignupUrl = `${environment.backendLink}/integration/zoom/signup`;

const resetPasswordUrl = `${environment.backendLinkPublic}/resetPassword`;

const integrationLogoutUrl = (client: string) =>
  `${environment.backendLink}/integration/${client}/logout`;

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _auth0Service = inject(AuthService);
  private _httpClient = inject(HttpClient);
  private _authStore = inject(AuthenticationStore);

  getUserInfo$() {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.get<UserInfoResponse>(
            getUserInfoUrl,
            jsonDefaultRequestOptions
          ),
          'getUserInfo$',
          this._authStore.logout
        );
      })
    );
  }

  updateUser$(payload: FormData) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.post<UserInfoResponse>(
            updateUserUrl,
            payload,
            defaultRequestOptions
          ),
          'updateUser$',
          this._authStore.logout
        );
      })
    );
  }

  validateUserEmail$(isValid: boolean) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.post<UserInfoResponse>(
            validateUserEmailUrl,
            undefined,
            {
              params: { isValid }
            }
          ),
          'validateUserEmail$',
          this._authStore.logout
        );
      })
    );
  }

  getOrgInfo$(orgId: string) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.get<OrgInfoResponse>(
            getOrgInfoUrl(orgId),
            jsonDefaultRequestOptions
          ),
          'getOrgInfo$',
          this._authStore.logout
        );
      })
    );
  }

  getOrgInfoWithEmail$(email: string) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.get<OrgInfoResponse>(getOrgInfoWithEmailUrl, {
            ...jsonDefaultRequestOptions,
            params: { email }
          }),
          'getOrgInfoWithEmail$',
          this._authStore.logout
        );
      })
    );
  }

  updateOrg$(payload: FormData) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.put<UpdateOrgResponse>(
            updateOrgUrl,
            payload,
            defaultRequestOptions
          ),
          'updateOrg$',
          this._authStore.logout
        );
      })
    );
  }

  promoteUser$(payload: PromoteUser) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.post<PossibleAttendee[]>(
            promoteUserUrl,
            payload,
            jsonDefaultRequestOptions
          ),
          'promoteuser$',
          this._authStore.logout
        );
      })
    );
  }

  deactivateUser$(payload: DeactivateUser) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.post<PossibleAttendee[]>(
            deactivateUserUrl,
            payload,
            jsonDefaultRequestOptions
          ),
          'deactivateUser$',
          this._authStore.logout
        );
      })
    );
  }

  googleSignup$(payload: IntegrationSignup) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.post(
            googleSignupUrl,
            payload,
            jsonDefaultRequestOptions
          ),
          'googleSignup$',
          this._authStore.logout,
          30000
        );
      })
    );
  }

  zoomSignup$(payload: IntegrationSignup) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.post<UserInfoResponse>(
            zoomSignupUrl,
            payload,
            jsonDefaultRequestOptions
          ),
          'zoomSignup$',
          this._authStore.logout,
          30000
        );
      })
    );
  }

  outlookSignup$(payload: OutlookSignup) {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.post<UserInfoResponse>(
            outlookSignupUrl,
            payload,
            jsonDefaultRequestOptions
          ),
          'zoomSignup$',
          this._authStore.logout,
          30000
        );
      })
    );
  }

  deactivateIntegration$(client: 'zoom' | 'google' | 'outlook') {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.post<UserInfoResponse>(
            integrationLogoutUrl(client),
            undefined,
            jsonDefaultRequestOptions
          ),
          'deactivateIntegration$',
          this._authStore.logout
        );
      })
    );
  }

  refreshVerifiedSenderStatus$() {
    return this._auth0Service.isAuthenticated$.pipe(
      filter((authenticated) => authenticated),
      switchMap(() => {
        return handleHttpResponse(
          this._httpClient.get<EmailValidationResponse>(
            refreshVerifiedEmailStatusUrl,
            jsonDefaultRequestOptions
          ),
          'refreshVerifiedSenderStatus$',
          this._authStore.logout
        );
      })
    );
  }

  resetPassword$(email: string) {
    return handlePublicHttpResponse(
      this._httpClient.post<UserInfoResponse>(
        resetPasswordUrl,
        { email },
        jsonDefaultRequestOptions
      ),
      'resetPassword$'
    );
  }
}
