import { environment } from 'src/environments/environment';

export const integrationLogoUrls = {
  teams: `${environment.assetsLink}/Microsoft_Outlook.svg`,
  zoom: `${environment.assetsLink}/zoom_logo.svg`,
  google: `${environment.assetsLink}/google_logo.svg`,
  axleAi: `${environment.assetsLink}/Axle_Meetings.svg`
};

export const defaultImageUrls = {
  addTagsPlaceholder: `${environment.assetsLink}/Add_Tags_Placeholder.svg`,
  chooseCustomTimesPlaceholder: `${environment.assetsLink}/Choose_Custom_Times_Placeholder.svg`,
  contactSelectionPlaceholder: `${environment.assetsLink}/Axle_Contacts_Selection_Placeholder.svg`,
  corporateLogo: `${environment.assetsLink}/Default_Corporate_Logo.svg`,
  darkModeHomeLogo: `${environment.assetsLink}/axle_dark_mode_multi_logo_home.svg`,
  darkModeIcon: `${environment.assetsLink}/Axle_Dark_Mode_Icon.svg`,
  darkModeLoginLogo: `${environment.assetsLink}/Axle_Login_Logo_Dark.svg`,
  emptyEventsPlaceholder: `${environment.assetsLink}/Axle_Empty_Events_Placeholder.svg`,
  emptyItineraryPlaceholder: `${environment.assetsLink}/Axle_Empty_Itinerary_Placeholder.svg`,
  eventNotFound: `${environment.assetsLink}/Axle_event_not_found.svg`,
  eventRequestsPlaceholder: `${environment.assetsLink}/Event_Requests_Placeholder.svg`,
  girlPlayingWithDials: `${environment.assetsLink}/Girl_Playing_With_Dials.svg`,
  girlTurningCalendarWithX: `${environment.assetsLink}/girl_turning_calendar_with_x.svg`,
  gmailIcon: `${environment.assetsLink}/Gmail_email_icon.svg`,
  importEventItineraryPlaceholder: `${environment.assetsLink}/Import_Event_Itinerary_Placeholder.svg`,
  legoBrickStacking: `${environment.assetsLink}/Axle_Empty_Events_Placeholder.svg`,
  lightModeHomeLogo: `${environment.assetsLink}/axle_light_mode_multi_logo_home.svg`,
  lightModeIcon: `${environment.assetsLink}/Axle_Light_Mode_Icon.svg`,
  lightModeLoginLogo: `${environment.assetsLink}/Axle_Login_Logo_Light.svg`,
  magnifyingGlassSearchPlaceholder: `${environment.assetsLink}/Magnifying_Glass_Search_Placeholder.svg`,
  manageMarketedOrgsPlaceholder: `${environment.assetsLink}/Manage_Marketed_Orgs_Placeholder.svg`,
  noContactMeetingsPlaceholder: `${environment.assetsLink}/No_Contact_Meetings_Placeholder.svg`,
  outlookIcon: `${environment.assetsLink}/outlook_email_icon.svg`,
  paperAirplanePlaceholder: `${environment.assetsLink}/Paper_Airplane_Placeholder.svg`,
  personRidingMoped: `${environment.assetsLink}/Person_Riding_Moped.svg`,
  sendEmail: `${environment.assetsLink}/Axle_Send_Email_Vector.svg`,
  somethingWentWrong: `${environment.assetsLink}/something_went_wrong.svg`,
  upArrow: `${environment.assetsLink}/Axle_Up_Arrow.svg`,
  userPic: `${environment.assetsLink}/Default_User_Pic.svg`,
  zoomLogo: `${environment.assetsLink}/zoom_logo.svg`
};
