import { Dialog } from '@angular/cdk/dialog';
import { inject } from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';
import { signalStore, withMethods, withState } from '@ngrx/signals';

import { withDevtools } from '../../functions/redux-dev-tools';
import { authenticationStoreMethods } from './authentication.methods';

export interface AuthenticationState {
  loggingIn: boolean;
  loggingOut: boolean;
}

export const initialState: AuthenticationState = {
  loggingIn: false,
  loggingOut: false
};

export const AuthenticationStore = signalStore(
  { providedIn: 'root' },
  withDevtools('authentication'),
  withState(initialState),
  withMethods((store) => {
    const authService = inject(AuthService);
    const dialog = inject(Dialog);
    return authenticationStoreMethods(authService, dialog, store);
  })
);
