import { HttpParams } from '@angular/common/http';
import { RedirectFunction, Routes } from '@angular/router';

import {
  determineAuthRedirectTo,
  determineRedirectTo,
  isAdmin,
  parentAuthGuardFn
} from './app/shared/guard/authentication.guards';
import { clearContactsStore } from './app/shared/guard/contacts.guards';
import {
  CampaignAutoSaveGuard,
  CampaignResetGuard,
  clearEmailStore,
  fetchEventDetails
} from './app/shared/guard/email-campaign.guards';
import { clearMeetingsStore } from './app/shared/guard/reporting.guards';
import { AxleEventsHomePaginatorService } from './app/shared/services/events/paginator/events-home-paginator.service';

export const redirectWithParams =
  (redirectPath: string): RedirectFunction =>
  ({ params = {}, queryParams = {} }) => {
    try {
      const resolvedPath = Object.entries(params).reduce(
        (path, [key, value]) =>
          path.replace(`:${key}`, encodeURIComponent(value ?? '')),
        redirectPath
      );

      return Object.keys(queryParams).length
        ? `${resolvedPath}?${new HttpParams({ fromObject: queryParams }).toString()}`
        : resolvedPath;
    } catch {
      return redirectPath;
    }
  };

export const routes: Routes = [
  {
    path: 'auth',
    children: [],
    canActivate: [determineAuthRedirectTo],
    title: 'Authentication'
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./app/features/login/login/login.component').then(
        (c) => c.AxleLoginComponent
      ),
    title: 'Login'
  },
  {
    path: 'password',
    children: [
      {
        path: 'reset',
        loadComponent: () =>
          import(
            './app/features/reset-password/container/reset-password-container.component'
          ).then((c) => c.AxleResetPasswordContainerComponent)
      }
    ],
    title: 'Password Reset'
  },
  {
    path: 'public',
    children: [
      {
        path: 'events',
        children: [
          {
            path: 'corporate/:id',
            redirectTo: redirectWithParams('/public/events/:id')
          },
          {
            path: ':id',
            loadComponent: () =>
              import(
                './app/features/public/container/axle-public-container.component'
              ).then((c) => c.AxlePublicContainerComponent),
            title: 'Event Details',
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './app/features/public/overview/axle-public-overview.component'
                  ).then((c) => c.AxlePublicOverviewComponent)
              },
              {
                path: 'additionalInfo',
                loadComponent: () =>
                  import(
                    './app/features/public/additional-info/registration/axle-public-registration-additional-info.component'
                  ).then(
                    (c) => c.AxlePublicAdditionalInfoRegistrationComponent
                  ),
                title: 'Additional Information'
              },
              {
                path: 'alreadyRegistered',
                loadComponent: () =>
                  import(
                    './app/features/public/already-registered/axle-public-already-registered.component'
                  ).then((c) => c.AxlePublicAlreadyRegisteredComponent),
                title: "You're Already Registered"
              },
              {
                path: 'attendees',
                title: 'Attendees',
                children: [
                  {
                    path: '',
                    loadComponent: () =>
                      import(
                        './app/features/public/attendees/axle-public-attendees-container.component'
                      ).then((c) => c.AxlePublicAttendeesContainerComponent),
                    children: [
                      {
                        path: '',
                        loadComponent: () =>
                          import(
                            './app/features/public/attendees/tables/attendee-table/axle-public-attendees-table.component'
                          ).then((c) => c.AxlePublicAttendeesTableComponent),
                        title: 'All Attendees'
                      },
                      {
                        path: 'requests',
                        loadComponent: () =>
                          import(
                            './app/features/public/attendees/tables/requests-table/axle-public-requests-table.component'
                          ).then((c) => c.AxlePublicRequestsTableComponent),
                        title: 'All Requests'
                      }
                    ]
                  }
                ]
              },
              {
                path: 'availability',
                loadComponent: () =>
                  import(
                    './app/features/public/availability/axle-public-availability.component'
                  ).then((c) => c.AxlePublicAvailabilityComponent),
                title: 'Add Availability'
              },
              {
                path: 'updateAvailability',
                loadComponent: () =>
                  import(
                    './app/features/public/availability/axle-public-availability.component'
                  ).then((c) => c.AxlePublicAvailabilityComponent),
                title: 'Update Availability',
                data: { mode: 'update' }
              },
              {
                path: 'corporateRegistration',
                loadComponent: () =>
                  import(
                    './app/features/public/corporate-registration/axle-public-corporate-registration.component'
                  ).then((c) => c.AxlePublicCorporateRegistrationComponent),
                title: 'Registration'
              },
              {
                path: 'eventNotFound',
                loadComponent: () =>
                  import(
                    './app/features/public/event-not-found/axle-public-event-not-found.component'
                  ).then((c) => c.AxlePublicEventNotFoundComponent),
                title: 'Event Not Found'
              },
              {
                path: 'hosts',
                loadComponent: () =>
                  import(
                    './app/features/public/hosts/axle-public-hosts-container.component'
                  ).then((c) => c.AxlePublicHostsContainerComponent),
                title: 'All Hosts'
              },
              {
                path: 'requests',
                loadComponent: () =>
                  import(
                    './app/features/public/corporate-requests-container/axle-corporate-requests.component'
                  ).then((c) => c.AxleCorporateRequestsContainerComponent),
                title: 'Requests',
                children: [
                  {
                    path: '',
                    loadComponent: () =>
                      import(
                        './app/features/public/corporate-requests-container/view-requests/view-requests.component'
                      ).then((c) => c.ViewRequestsComponent),
                    title: 'All Requests'
                  },
                  {
                    path: 'approve',
                    loadComponent: () =>
                      import(
                        './app/features/public/corporate-requests-container/approve-schedules/approve-schedules.component'
                      ).then((c) => c.ApproveSchedulesComponent),
                    title: 'Approve Requests'
                  }
                ]
              },
              {
                path: 'meetings',
                loadComponent: () =>
                  import(
                    './app/features/public/meetings/axle-public-meetings.component'
                  ).then((c) => c.AxlePublicMeetingsComponent),
                title: 'Meeting Schedules'
              },
              {
                path: 'participation',
                loadComponent: () =>
                  import(
                    './app/features/public/corporate-participation/axle-public-participation.component'
                  ).then((c) => c.AxlePublicParticipationComponent),
                title: 'Your Participation'
              },
              {
                path: 'presentations',
                loadComponent: () =>
                  import(
                    './app/features/public/presentations/container/axle-public-presentations-container.component'
                  ).then((c) => c.AxlePublicPresentationsContainerComponent),
                title: 'Presentations'
              },
              {
                path: 'questionnaire',
                loadComponent: () =>
                  import(
                    './app/features/public/questionnaire/axle-public-questionnaire.component'
                  ).then((c) => c.AxlePublicQuestionnaireComponent),
                title: 'Questionnaire'
              },
              {
                path: 'rsvp',
                children: [
                  {
                    path: 'questionnaire',
                    children: [
                      {
                        path: '',
                        loadComponent: () =>
                          import(
                            './app/features/public/questionnaire/rsvp/axle-rsvp-questionnaire.component'
                          ).then((c) => c.AxleRsvpQuestionnaireComponent),
                        title: 'Questionnaire'
                      },
                      {
                        path: 'update',
                        loadComponent: () =>
                          import(
                            './app/features/public/questionnaire/rsvp/axle-rsvp-questionnaire.component'
                          ).then((c) => c.AxleRsvpQuestionnaireComponent),
                        title: 'Update RSVP',
                        data: { mode: 'update' }
                      }
                    ]
                  },
                  {
                    path: 'additionalInfo',
                    loadComponent: () =>
                      import(
                        './app/features/public/additional-info/rsvp/axle-public-rsvp-additional-info.component'
                      ).then((c) => c.AxlePublicAdditionalInfoRsvpComponent),
                    title: 'Additional Information'
                  },
                  {
                    path: 'success',
                    loadComponent: () =>
                      import(
                        './app/features/public/rsvp-success/axle-rsvp-success.component'
                      ).then((c) => c.AxleRsvpSuccessComponent),
                    title: 'RSVP Successful'
                  }
                ],
                title: 'RSVP Event'
              },
              {
                path: 'updateQuestionnaire',
                loadComponent: () =>
                  import(
                    './app/features/public/questionnaire/axle-public-questionnaire.component'
                  ).then((c) => c.AxlePublicQuestionnaireComponent),
                title: 'Update Questionnaire',
                data: { mode: 'update' }
              },
              {
                path: 'registerColleague',
                loadComponent: () =>
                  import(
                    './app/features/public/register-colleague/axle-register-colleague.component'
                  ).then((c) => c.AxleRegisterColleagueComponent),
                title: 'Register a Colleague'
              },
              {
                path: 'registerNewColleague',
                loadComponent: () =>
                  import(
                    './app/features/public/register-new-colleague/axle-register-new-colleague.component'
                  ).then((c) => c.AxleRegisterNewColleagueComponent),
                title: 'Register a New Colleague'
              },
              {
                path: 'registrationSuccess',
                loadComponent: () =>
                  import(
                    './app/features/public/registration-success/axle-registration-success.component'
                  ).then((c) => c.AxleRegistrationSuccessComponent),
                title: 'Registration Successful'
              },
              {
                path: 'requestedOrgs',
                loadComponent: () =>
                  import(
                    './app/features/public/requested-orgs/axle-public-requested-orgs.component'
                  ).then((c) => c.AxlePublicRequestedOrgsComponent),
                title: 'Requested Organizations'
              },
              {
                path: 'updateRequestedOrgs',
                loadComponent: () =>
                  import(
                    './app/features/public/requested-orgs/axle-public-requested-orgs.component'
                  ).then((c) => c.AxlePublicRequestedOrgsComponent),
                title: 'Update Requested Organizations',
                data: { mode: 'update' }
              },
              {
                path: 'selectMeeting',
                loadComponent: () =>
                  import(
                    './app/features/public/select-meeting-time/axle-public-select-meeting-time.component'
                  ).then((c) => c.AxlePublicSelectMeetingTimeComponent),
                title: 'Select a Meeting'
              },
              {
                path: 'selectMeetings',
                loadComponent: () =>
                  import(
                    './app/features/public/select-meetings/axle-public-select-meetings.component'
                  ).then((c) => c.AxlePublicSelectMeetingsComponent),
                title: 'Select Meetings'
              },
              {
                path: 'updateSelectMeetings',
                loadComponent: () =>
                  import(
                    './app/features/public/select-meetings/axle-public-select-meetings.component'
                  ).then((c) => c.AxlePublicSelectMeetingsComponent),
                title: 'Update Selected Meetings',
                data: { mode: 'update' }
              },
              {
                path: 'updateSelectMeeting',
                loadComponent: () =>
                  import(
                    './app/features/public/select-meeting-time/axle-public-select-meeting-time.component'
                  ).then((c) => c.AxlePublicSelectMeetingTimeComponent),
                title: 'Update Selected Meeting',
                data: { mode: 'update' }
              }
            ]
          }
        ],
        title: 'Your Event'
      }
    ]
  },
  {
    path: '',
    loadComponent: () =>
      import(
        './app/shared/components/containers/auth-container/auth-container.component'
      ).then((c) => c.AxleAuthContainerComponent),
    children: [
      {
        path: 'admin-v777rpNsg_5hjfsdkhjsdfkhjsdfkjhsdfmuegcko6F4w',
        canActivate: [isAdmin],
        loadComponent: () =>
          import(
            './app/features/admin/admin-container/admin-container.component'
          ).then((c) => c.AxleAdminContainerComponent),
        title: 'Admin Portal'
      },
      {
        path: 'home',
        loadComponent: () =>
          import('./app/features/home/container/home-container.component').then(
            (c) => c.AxleHomeContainerComponent
          ),
        title: 'Home',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './app/features/home/primary-container/home-primary-container.component'
              ).then((c) => c.AxleHomePrimaryContainerComponent),
            title: 'Home'
          }
        ]
      },
      {
        path: 'contacts',
        loadComponent: () =>
          import(
            './app/features/contacts/reskin/container/axle-contacts-container.component'
          ).then((c) => c.AxleContactsContainerComponent),
        canDeactivate: [clearContactsStore],
        title: 'Contacts',
        children: [
          {
            path: 'user',
            loadComponent: () =>
              import(
                './app/features/contacts/reskin/user/container/axle-user-contacts-container.component'
              ).then((c) => c.AxleUserContactsContainerComponent),
            title: 'Contacts (Users)',
            children: [
              {
                path: ':id',
                loadComponent: () =>
                  import(
                    './app/features/contacts/reskin/user/details/container/axle-user-details-slideout.component'
                  ).then((c) => c.AxleUserDetailsSlideoutComponent),
                title: 'Contacts (User Details)',
                children: [
                  {
                    path: 'overview',
                    loadComponent: () =>
                      import(
                        './app/features/contacts/reskin/user/details/overview/axle-user-details-overview.component'
                      ).then((c) => c.AxleUserDetailsOverviewComponent),
                    title: 'Contacts (User Details) - Overview'
                  },
                  {
                    path: 'tags',
                    loadComponent: () =>
                      import(
                        './app/features/contacts/reskin/user/details/tags/axle-user-details-tags.component'
                      ).then((c) => c.AxleUserDetailsTagsComponent),
                    title: 'Contacts (User Details) - Tags'
                  },
                  {
                    path: 'coverage',
                    loadComponent: () =>
                      import(
                        './app/features/contacts/reskin/user/details/coverage/container/axle-user-coverage-container.component'
                      ).then((c) => c.AxleUserCoverageContainerComponent),
                    title: 'Contacts (User Details) - Coverage'
                  },
                  {
                    path: 'notes',
                    loadComponent: () =>
                      import(
                        './app/features/contacts/reskin/user/details/notes/axle-user-details-notes.component'
                      ).then((c) => c.AxleUserDetailsNotesComponent),
                    title: 'Contacts (User Details) - Notes'
                  },
                  {
                    path: '**',
                    redirectTo: 'overview'
                  }
                ]
              }
            ]
          },
          {
            path: 'org',
            loadComponent: () =>
              import(
                './app/features/contacts/reskin/org/container/axle-org-contacts-container.component'
              ).then((c) => c.AxleOrgContactsContainerComponent),
            title: 'Contacts (Organizations)',
            children: [
              {
                path: ':id',
                loadComponent: () =>
                  import(
                    './app/features/contacts/reskin/org/details/container/axle-org-details-slideout.component'
                  ).then((c) => c.AxleOrgDetailsSlideoutComponent),
                title: 'Contacts (Organization Details)',
                children: [
                  {
                    path: 'overview',
                    loadComponent: () =>
                      import(
                        './app/features/contacts/reskin/org/details/overview/axle-org-details-overview.component'
                      ).then((c) => c.AxleOrgDetailsOverviewComponent),
                    title: 'Contacts (Organization Details) - Overview'
                  },
                  {
                    path: 'tags',
                    loadComponent: () =>
                      import(
                        './app/features/contacts/reskin/org/details/tags/axle-org-details-tags.component'
                      ).then((c) => c.AxleOrgDetailsTagsComponent),
                    title: 'Contacts (Organization Details) - Tags'
                  },
                  {
                    path: 'coverage',
                    loadComponent: () =>
                      import(
                        './app/features/contacts/reskin/org/details/coverage/container/axle-org-coverage-container.component'
                      ).then((c) => c.AxleOrgCoverageContainerComponent),
                    title: 'Contacts (Organization Details) - Coverage'
                  },
                  {
                    path: 'notes',
                    loadComponent: () =>
                      import(
                        './app/features/contacts/reskin/org/details/notes/axle-org-details-notes.component'
                      ).then((c) => c.AxleOrgDetailsNotesComponent),
                    title: 'Contacts (Organization Details) - Notes'
                  },
                  {
                    path: 'enrichment',
                    loadComponent: () =>
                      import(
                        './app/features/contacts/reskin/org/details/enrichment/container/axle-org-enrichment-container.component'
                      ).then((c) => c.AxleOrgEnrichmentContainerComponent),
                    title: 'Contacts (Organization Details) - Enrichment'
                  },
                  {
                    path: '**',
                    redirectTo: 'overview'
                  }
                ]
              }
            ]
          },
          {
            path: '**',
            redirectTo: 'user'
          }
        ]
      },
      {
        path: 'email',
        loadComponent: () =>
          import(
            './app/features/email/container/axle-email-container.component'
          ).then((c) => c.AxleEmailContainerComponent),
        providers: [AxleEventsHomePaginatorService],
        canActivate: [fetchEventDetails],
        canDeactivate: [clearEmailStore],
        title: 'Email',
        children: [
          {
            path: 'activity',
            loadComponent: () =>
              import(
                './app/features/email/activity/container/axle-email-activity-container.component'
              ).then((c) => c.AxleEmailActivityContainerComponent),
            title: 'Email Activity',
            children: [
              {
                path: 'drafts',
                loadComponent: () =>
                  import(
                    './app/features/email/activity/drafts/container/axle-email-activity-drafts-container.component'
                  ).then((c) => c.AxleEmailActivityDraftsContainerComponent),
                title: 'Draft Emails'
              },
              {
                path: 'sent',
                loadComponent: () =>
                  import(
                    './app/features/email/activity/sent/container/axle-email-activity-sent-container.component'
                  ).then((c) => c.AxleEmailActivitySentContainerComponent),
                title: 'Sent Emails'
              },
              {
                path: '**',
                redirectTo: 'sent'
              }
            ]
          },
          {
            path: 'campaign',
            loadComponent: () =>
              import(
                './app/features/email/campaign-manager/container/axle-email-campaign-manager-container.component'
              ).then((c) => c.AxleEmailCampaignManagerContainerComponent),
            canActivate: [CampaignResetGuard],
            canDeactivate: [CampaignAutoSaveGuard],
            title: 'Email Campaigns',
            children: [
              {
                path: 'new',
                children: [
                  {
                    path: 'editor',

                    loadComponent: () =>
                      import(
                        './app/features/email/campaign-manager/editor/container/axle-email-campaign-manager-editor-container.component'
                      ).then(
                        (c) =>
                          c.AxleEmailCampaignManagerEditorContainerComponent
                      ),
                    title: 'New Campaign Editor'
                  },
                  {
                    path: 'audience',
                    loadComponent: () =>
                      import(
                        './app/features/email/campaign-manager/audience-selection/container/axle-email-campaign-manager-audience-selection-container.component'
                      ).then(
                        (c) =>
                          c.AxleEmailCampaignManagerAudienceSelectionContainerComponent
                      ),
                    title: 'New Campaign Audience'
                  },
                  {
                    path: 'metrics',
                    loadComponent: () =>
                      import(
                        './app/features/email/campaign-manager/metrics/placeholder/axle-email-campaign-manager-metrics-placeholder.component'
                      ).then(
                        (c) =>
                          c.AxleEmailCampaignManagerMetricsPlaceholderComponent
                      ),
                    title: 'Campaign Metrics'
                  },
                  {
                    path: '**',
                    redirectTo: 'editor'
                  }
                ]
              },
              {
                path: ':id',
                title: 'Campaign Details',
                children: [
                  {
                    path: 'editor',
                    loadComponent: () =>
                      import(
                        './app/features/email/campaign-manager/editor/container/axle-email-campaign-manager-editor-container.component'
                      ).then(
                        (c) =>
                          c.AxleEmailCampaignManagerEditorContainerComponent
                      ),
                    title: 'Campaign Editor'
                  },
                  {
                    path: 'audience',
                    loadComponent: () =>
                      import(
                        './app/features/email/campaign-manager/audience-selection/container/axle-email-campaign-manager-audience-selection-container.component'
                      ).then(
                        (c) =>
                          c.AxleEmailCampaignManagerAudienceSelectionContainerComponent
                      ),
                    title: 'Campaign Audience'
                  },
                  {
                    path: 'metrics',
                    loadComponent: () =>
                      import(
                        './app/features/email/campaign-manager/metrics/container/axle-email-campaign-manager-metrics-container.component'
                      ).then(
                        (c) =>
                          c.AxleEmailCampaignManagerMetricsContainerComponent
                      ),
                    title: 'Campaign Metrics'
                  },
                  {
                    path: '**',
                    redirectTo: 'editor'
                  }
                ]
              }
            ]
          },
          {
            path: 'notifications',
            loadComponent: () =>
              import(
                './app/features/email/notifications/container/axle-email-notifications-container.component'
              ).then((c) => c.AxleEmailNotificationsContainerComponent),
            title: 'Email Notifications',
            canActivate: [fetchEventDetails],
            providers: [AxleEventsHomePaginatorService],
            children: [
              {
                path: 'editor/:id',
                loadComponent: () =>
                  import(
                    './app/features/email/notifications/editor/axle-email-notifications-editor.component'
                  ).then((c) => c.AxleEmailNotificationsEditorComponent),
                title: 'Email Notifications (Editor)'
              },
              {
                path: '',
                loadComponent: () =>
                  import(
                    './app/features/email/notifications/overview-container/axle-email-notifications-overview-container.component'
                  ).then(
                    (c) => c.AxleEmailNotificationsOverviewContainerComponent
                  ),
                title: 'Email Notifications',
                children: [
                  {
                    path: 'host',
                    loadComponent: () =>
                      import(
                        './app/features/email/notifications/host/axle-email-notifications-host-container.component'
                      ).then(
                        (c) => c.AxleEmailNotificationsHostContainerComponent
                      ),
                    title: 'Email Notifications (Host)'
                  },
                  {
                    path: 'guest',
                    loadComponent: () =>
                      import(
                        './app/features/email/notifications/guest/axle-email-notifications-guest-container.component'
                      ).then(
                        (c) => c.AxleEmailNotificationsGuestContainerComponent
                      ),
                    title: 'Email Notifications (Guest)'
                  },
                  {
                    path: 'coordinator',
                    loadComponent: () =>
                      import(
                        './app/features/email/notifications/coordinator/axle-email-notifications-coordinator-container.component'
                      ).then(
                        (c) =>
                          c.AxleEmailNotificationsCoordinatorContainerComponent
                      ),
                    title: 'Email Notifications (Coordinator)'
                  },
                  {
                    path: '**',
                    redirectTo: 'host'
                  }
                ]
              }
            ]
          },
          {
            path: '**',
            redirectTo: 'activity'
          }
        ]
      },
      {
        path: 'events',
        loadComponent: () =>
          import(
            './app/features/events/container/events-container.component'
          ).then((c) => c.AxleEventsContainerComponent),
        title: 'Events',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './app/features/events/home/overview/axle-events-overview.component'
              ).then((c) => c.AxleEventsOverviewComponent),
            title: 'All Events'
          },
          {
            path: 'create',
            loadComponent: () =>
              import(
                './app/features/events/create/container/create-event-container.component'
              ).then((c) => c.AxleCreateEventContainerComponent),
            title: 'Create Event',
            data: { mode: 'CREATE' }
          },
          {
            path: ':id',
            loadComponent: () =>
              import(
                './app/features/events/details/container/events-details.component'
              ).then((c) => c.AxleEventsDetailsComponent),
            title: 'Event Details',
            children: [
              {
                path: 'update',
                loadComponent: () =>
                  import(
                    './app/features/events/create/container/create-event-container.component'
                  ).then((c) => c.AxleCreateEventContainerComponent),
                title: 'Update Event Details',
                data: { mode: 'UPDATE' }
              },
              {
                path: 'targetList',
                loadComponent: () =>
                  import(
                    './app/features/events/target-list/target-list.component'
                  ).then((c) => c.AxleTargetListComponent),
                title: 'Target List'
              },
              {
                path: 'portalPreferences',
                loadComponent: () =>
                  import(
                    './app/features/events/preferences/request/axle-event-request-preferences.component'
                  ).then((c) => c.AxleEventRequestPreferencesComponent),
                title: 'Portal Preferences'
              },
              {
                path: 'questionnaireBuilder',
                loadComponent: () =>
                  import(
                    './app/features/events/questionnaire-container/axle-events-questionnaire-container.component'
                  ).then((c) => c.AxleEventsQuestionnaireContainerComponent),
                title: 'Manage Questionnaires'
              },
              {
                path: 'generalInterestList',
                loadComponent: () =>
                  import(
                    './app/features/events/general-interest-list/axle-general-interest-list.component'
                  ).then((c) => c.AxleGeneralInterestListComponent),
                title: 'General Interest List'
              },
              {
                path: 'invitePreferences',
                loadComponent: () =>
                  import(
                    './app/features/events/preferences/invite/axle-event-invite-preferences.component'
                  ).then((c) => c.AxleEventInvitePreferencesComponent),
                title: 'Invite Preferences'
              },
              {
                path: 'itinerary',
                loadComponent: () =>
                  import(
                    './app/features/events/itinerary/container/axle-event-itinerary-container.component'
                  ).then((c) => c.AxleEventItineraryContainerComponent),
                title: 'Itinerary',
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: 'org'
                  },
                  {
                    path: 'org',
                    loadComponent: () =>
                      import(
                        './app/features/events/itinerary/org-level/event-org-itinerary.component'
                      ).then((c) => c.AxleEventOrgItineraryComponent),
                    title: 'Meeting Itinerary',
                    data: { feature: 'ORG_LEVEL' }
                  },
                  {
                    path: 'event',
                    loadComponent: () =>
                      import(
                        './app/features/events/itinerary/event-level/event-level-itinerary.component'
                      ).then((c) => c.AxleEventLevelItineraryComponent),
                    title: 'Event Itinerary',
                    data: { feature: 'EVENT_LEVEL' }
                  },
                  {
                    path: 'presentations',
                    loadComponent: () =>
                      import(
                        './app/features/events/presentations/container/axle-event-presentations.component'
                      ).then((c) => c.AxleEventPresentationsComponent),
                    title: 'Presentations',
                    data: { feature: 'PRESENTATIONS' }
                  },
                  {
                    path: 'rooms',
                    loadComponent: () =>
                      import(
                        './app/features/events/itinerary/rooms/axle-event-rooms.component'
                      ).then((c) => c.AxleEventRoomsComponent),
                    title: 'Rooms',
                    data: { feature: 'ROOMS' }
                  },
                  {
                    path: 'travel',
                    loadComponent: () =>
                      import(
                        './app/features/events/travel-logistics/container/axle-travel-logistics-container.component'
                      ).then((c) => c.AxleTravelLogisticsContainerComponent),
                    title: 'Travel',
                    data: { feature: 'TRAVEL' }
                  }
                ]
              },
              {
                path: 'allocate',
                loadComponent: () =>
                  import(
                    './app/features/events/allocation-manager/container/allocation-manager.component'
                  ).then((c) => c.AxleAllocationManagerComponent),
                title: 'Allocation Manager'
              },
              {
                path: 'activityLog',
                loadComponent: () =>
                  import(
                    './app/features/events/activity-log/container/axle-activity-log-container.component'
                  ).then((c) => c.AxleActivityLogContainerComponent),
                title: 'Activity Log',
                children: [
                  {
                    path: 'eventChanges',
                    loadComponent: () =>
                      import(
                        './app/features/events/activity-log/event-changes/event-changes.component'
                      ).then((c) => c.AxleEventActivityLogComponent),
                    title: 'Activity Log - Event Changes',
                    data: { feature: 'EVENT_CHANGES' }
                  },
                  {
                    path: 'requestUpdates',
                    loadComponent: () =>
                      import(
                        './app/features/events/activity-log/request-updates/axle-request-updates-container.component'
                      ).then((c) => c.AxleRequestUpdatesContainerComponent),
                    title: 'Activity Log - Request Changes',
                    data: { feature: 'REQUEST_UPDATES' }
                  }
                ]
              },
              {
                path: '',
                loadComponent: () =>
                  import(
                    './app/features/events/details/main/axle-events-main-container-component.component'
                  ).then((c) => c.AxleEventDetailsMainContainerComponent)
              }
            ]
          }
        ]
      },
      {
        path: 'reporting',
        loadComponent: () =>
          import(
            './app/features/reporting/container/reporting-container.component'
          ).then((c) => c.AxleReportingContainerComponent),
        canDeactivate: [clearMeetingsStore],
        title: 'Meetings'
      },
      {
        path: 'meeting',
        loadComponent: () =>
          import(
            './app/features/upload-meetings/edit-container/edit-meetings-container.component'
          ).then((c) => c.AxleEditMeetingsComponent)
      },
      {
        path: 'uploadMeetings',
        loadComponent: () =>
          import(
            './app/features/upload-meetings/upload-container/upload-meetings-container.component'
          ).then((c) => c.AxleUploadMeetingsComponent),
        title: 'Upload Meetings'
      },
      {
        path: 'profile',
        loadComponent: () =>
          import(
            './app/features/profile/container/profile-container.component'
          ).then((c) => c.AxleProfileContainerComponent),
        title: 'Profile',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'user'
          },
          {
            path: 'user',
            loadComponent: () =>
              import(
                './app/features/profile/user/axle-user-profile.component'
              ).then((c) => c.AxleUserProfileComponent),
            title: 'User Profile'
          },
          {
            path: 'org',
            loadComponent: () =>
              import(
                './app/features/profile/org/axle-org-profile.component'
              ).then((c) => c.AxleOrgProfileComponent),
            title: 'Organization Profile'
          }
        ]
      },
      {
        path: 'preferences',
        loadComponent: () =>
          import(
            './app/features/preferences/preferences-container/preferences-container.component'
          ).then((c) => c.AxlePreferencesContainerComponent),
        title: 'Preferences'
      },
      {
        path: '**',
        pathMatch: 'full',
        children: [],
        canActivate: [determineRedirectTo]
      }
    ],
    canActivate: [parentAuthGuardFn]
  },
  {
    path: '**',
    pathMatch: 'full',
    children: [],
    canActivate: [determineRedirectTo]
  }
];
