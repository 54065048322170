import { ErrorResponse, RxMethodCallback } from '../../models/rxMethod.models';
import {
  DeactivateUserPayload,
  IntegrationSignupPayload,
  OrgInfoRequest,
  OrgInfoResponse,
  OutlookSignupPayload,
  PossibleAttendee,
  PromoteUserPayload,
  UpdateOrgPayload,
  UpdateOrgRequest,
  UpdateOrgResponse,
  UpdateUserPayload,
  UpdateUserRequest,
  UpdateUserResponse,
  UserInfo,
  UserInfoResponse
} from './user.models';
import { UserService } from './user.service';
import { UserState, possibleAttendeesCollection } from './user.state';
import { Signal } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState } from '@ngrx/signals';
import { removeEntity, updateEntity } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, tap } from 'rxjs';

import { environment } from 'src/environments/environment';

export function updateUser(
  userService: UserService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) {
  return rxMethod<UpdateUserRequest & RxMethodCallback<UpdateUserResponse>>(
    pipe(
      tap(() => patchState<UserState>(state, { updateUserInflight: true })),
      switchMap(({ payload, profilePicture, onSuccess, onFail }) =>
        userService
          .updateUser$(
            _buildUpdatePayload(
              payload,
              'updateUser',
              'profilePicture',
              profilePicture
            )
          )
          .pipe(
            tapResponse({
              next: (updateUserResponse) => {
                patchState(state, { updateUserInflight: false, error: false });
                patchState(state, { userInfo: updateUserResponse });

                onSuccess?.(updateUserResponse);
              },
              error: () => {
                patchState(state, { updateUserInflight: false, error: true });
                onFail?.();
              }
            })
          )
      )
    )
  );
}

export function validateUserEmail(
  userService: UserService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
  userInfo: Signal<UserInfo | null>
) {
  return rxMethod<{ isValid: boolean } & RxMethodCallback<UserInfoResponse>>(
    pipe(
      tap(() => patchState<UserState>(state, { validateEmailInflight: true })),
      switchMap(({ isValid, onSuccess, onFail }) =>
        userService.validateUserEmail$(isValid).pipe(
          tapResponse({
            next: (response) => {
              if (!isValid) {
                const currentInfo = userInfo();
                if (currentInfo) {
                  const newInfo: UserInfo = {
                    ...currentInfo,
                    isEmailVerified: false,
                    lastVerificationEmail: new Date().toISOString()
                  };
                  patchState(state, { userInfo: newInfo });
                }
              }
              patchState(state, { validateEmailInflight: false });
              onSuccess?.(response);
            },
            error: () => {
              patchState(state, { validateEmailInflight: false });
              onFail?.();
            }
          })
        )
      )
    )
  );
}

export function getOrgInfo(
  userService: UserService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) {
  return rxMethod<OrgInfoRequest & RxMethodCallback<OrgInfoResponse>>(
    pipe(
      tap(() => patchState<UserState>(state, { getOrgInfoInflight: true })),
      switchMap(({ orgId, onSuccess, onFail }) =>
        userService.getOrgInfo$(orgId).pipe(
          tapResponse({
            next: (orgInfo) => {
              patchState<UserState>(state, {
                getOrgInfoInflight: false,
                error: false,
                orgInfo
              });
              onSuccess?.(orgInfo);
            },
            error: () => {
              patchState<UserState>(state, {
                getOrgInfoInflight: false,
                error: true
              });
              onFail?.();
            }
          })
        )
      )
    )
  );
}

export function getOrgInfoWithEmail(
  userService: UserService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) {
  return rxMethod<{ email: string } & RxMethodCallback<OrgInfoResponse>>(
    pipe(
      tap(() =>
        patchState<UserState>(state, { getOrgInfoWithEmailInflight: true })
      ),
      switchMap(({ email, onSuccess, onFail }) =>
        userService.getOrgInfoWithEmail$(email).pipe(
          tapResponse({
            next: (orgInfo) => {
              patchState<UserState>(state, {
                getOrgInfoWithEmailInflight: false
              });
              onSuccess?.(orgInfo);
            },
            error: (error: ErrorResponse) => {
              patchState<UserState>(state, {
                getOrgInfoWithEmailInflight: false
              });
              onFail?.(error);
            }
          })
        )
      )
    )
  );
}

export function updateOrg(
  userService: UserService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) {
  return rxMethod<UpdateOrgRequest & RxMethodCallback<UpdateOrgResponse>>(
    pipe(
      tap(() => patchState<UserState>(state, { updateOrgInflight: true })),
      switchMap(({ payload, profilePicture, onSuccess, onFail }) =>
        userService
          .updateOrg$(
            _buildUpdatePayload(payload, 'organization', 'logo', profilePicture)
          )
          .pipe(
            tapResponse({
              next: (updateOrgResponse) => {
                patchState(state, { updateOrgInflight: false, error: false });
                onSuccess?.(updateOrgResponse);
              },
              error: () => {
                patchState(state, { updateOrgInflight: false, error: true });
                onFail?.();
              }
            })
          )
      )
    )
  );
}

export function promoteUser(
  userService: UserService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) {
  return rxMethod<PromoteUserPayload & RxMethodCallback<PossibleAttendee[]>>(
    pipe(
      tap(() => patchState<UserState>(state, { promoteUserInflight: true })),
      switchMap(({ payload, onSuccess, onFail }) =>
        userService.promoteUser$(payload).pipe(
          tapResponse({
            next: (users) => {
              patchState(
                state,
                updateEntity(
                  { id: payload.userId, changes: { roles: [payload.role] } },
                  possibleAttendeesCollection
                )
              );
              patchState(state, { promoteUserInflight: false });
              onSuccess?.(users);
            },
            error: () => {
              patchState(state, { promoteUserInflight: false });
              onFail?.();
            }
          })
        )
      )
    )
  );
}

export function deactivateUser(
  userService: UserService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) {
  return rxMethod<DeactivateUserPayload & RxMethodCallback<PossibleAttendee[]>>(
    pipe(
      tap(() => patchState<UserState>(state, { deactivateUserInflight: true })),
      switchMap(({ payload, onSuccess, onFail }) =>
        userService.deactivateUser$(payload).pipe(
          tapResponse({
            next: (users) => {
              patchState(
                state,
                removeEntity(payload.userId, possibleAttendeesCollection)
              );
              patchState(state, { deactivateUserInflight: false });
              onSuccess?.(users);
            },
            error: () => {
              patchState(state, { deactivateUserInflight: false });
              onFail?.();
            }
          })
        )
      )
    )
  );
}

export function connectGoogle(
  userService: UserService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
  userInfo: Signal<UserInfo | null>
) {
  return rxMethod<IntegrationSignupPayload & RxMethodCallback<void>>(
    pipe(
      tap(() => patchState<UserState>(state, { connectGoogleInflight: true })),
      switchMap(({ payload, onSuccess, onFail }) =>
        userService.googleSignup$(payload).pipe(
          tapResponse({
            next: () => {
              const currentInfo = userInfo();
              if (currentInfo) {
                const newInfo = {
                  ...currentInfo,
                  integrations: {
                    ...currentInfo.integrations,
                    google: true
                  }
                };
                patchState(state, { userInfo: newInfo });
                patchState(
                  state,
                  updateEntity(
                    { id: currentInfo.id, changes: { google: true } },
                    possibleAttendeesCollection
                  )
                );
              }

              patchState(state, { connectGoogleInflight: false });
              onSuccess?.();
            },
            error: () => {
              patchState(state, { connectGoogleInflight: false });
              onFail?.();
            }
          })
        )
      )
    )
  );
}

export function connectOutlook(
  userService: UserService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
  userInfo: Signal<UserInfo | null>
) {
  return rxMethod<OutlookSignupPayload & RxMethodCallback<void>>(
    pipe(
      tap(() => patchState<UserState>(state, { connectOutlookInflight: true })),
      switchMap(({ payload, onSuccess, onFail }) =>
        userService.outlookSignup$(payload).pipe(
          tapResponse({
            next: () => {
              const currentInfo = userInfo();
              if (currentInfo) {
                const newInfo = {
                  ...currentInfo,
                  integrations: {
                    ...currentInfo.integrations,
                    outlook: true
                  }
                };
                patchState(state, { userInfo: newInfo });
                patchState(
                  state,
                  updateEntity(
                    { id: currentInfo.id, changes: { outlook: true } },
                    possibleAttendeesCollection
                  )
                );
              }
              patchState(state, { connectOutlookInflight: false });
              onSuccess?.();
            },
            error: () => {
              patchState(state, { connectOutlookInflight: false });
              onFail?.();
            }
          })
        )
      )
    )
  );
}

export function connectZoom(
  userService: UserService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
  userInfo: Signal<UserInfo | null>
) {
  return rxMethod<IntegrationSignupPayload & RxMethodCallback<void>>(
    pipe(
      tap(() => patchState<UserState>(state, { connectZoomInflight: true })),
      switchMap(({ payload, onSuccess, onFail }) =>
        userService.zoomSignup$(payload).pipe(
          tapResponse({
            next: () => {
              const currentInfo = userInfo();
              if (currentInfo) {
                const newInfo = {
                  ...currentInfo,
                  integrations: {
                    ...currentInfo.integrations,
                    zoom: true
                  }
                };
                patchState(state, { userInfo: newInfo });
                patchState(
                  state,
                  updateEntity(
                    { id: currentInfo.id, changes: { zoom: true } },
                    possibleAttendeesCollection
                  )
                );
              }
              patchState(state, { connectZoomInflight: false });
              onSuccess?.();
            },
            error: () => {
              patchState(state, { connectZoomInflight: false });
              onFail?.();
            }
          })
        )
      )
    )
  );
}

export function logoutIntegration(
  userService: UserService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
  userInfo: Signal<UserInfo | null>
) {
  return rxMethod<
    {
      client: 'zoom' | 'google' | 'outlook';
    } & RxMethodCallback<void>
  >(
    pipe(
      tap(() =>
        patchState<UserState>(state, { deactivateIntegrationInflight: true })
      ),
      switchMap(({ client, onSuccess, onFail }) =>
        userService.deactivateIntegration$(client).pipe(
          tapResponse({
            next: () => {
              const currentInfo = userInfo();
              if (currentInfo) {
                const newInfo = {
                  ...currentInfo,
                  integrations: {
                    ...currentInfo.integrations,
                    [client]: false
                  }
                };
                patchState(state, { userInfo: newInfo });
                patchState(
                  state,
                  updateEntity(
                    { id: currentInfo.id, changes: { [client]: false } },
                    possibleAttendeesCollection
                  )
                );
              }
              patchState(state, { deactivateIntegrationInflight: false });
              onSuccess?.();
            },
            error: () => {
              patchState(state, { deactivateIntegrationInflight: false });
              onFail?.();
            }
          })
        )
      )
    )
  );
}

export function refreshVerifiedEmailStatus(
  userService: UserService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
  userInfo: Signal<UserInfo | null>
) {
  return rxMethod<RxMethodCallback<void>>(
    pipe(
      tap(() =>
        patchState<UserState>(state, {
          refreshEmailValidationStatusInflight: true
        })
      ),
      switchMap(({ onSuccess, onFail }) =>
        userService.refreshVerifiedSenderStatus$().pipe(
          tapResponse({
            next: (response) => {
              const currentInfo = userInfo();
              if (currentInfo) {
                const newInfo: UserInfo = {
                  ...currentInfo,
                  isEmailVerified: response.isValid,
                  lastVerificationEmail: response.lastValidationResponse
                };
                patchState(state, { userInfo: newInfo });
              }

              patchState(state, {
                refreshEmailValidationStatusInflight: false
              });
              onSuccess?.();
            },
            error: () => {
              patchState(state, {
                refreshEmailValidationStatusInflight: false
              });
              onFail?.();
            }
          })
        )
      )
    )
  );
}

export function resetPassword(
  userService: UserService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) {
  return rxMethod<{ email: string } & RxMethodCallback<void>>(
    pipe(
      tap(() =>
        patchState<UserState>(state, {
          resetPasswordInflight: true
        })
      ),
      switchMap(({ email, onSuccess, onFail }) =>
        userService.resetPassword$(email).pipe(
          tapResponse({
            next: () => {
              patchState(state, {
                resetPasswordInflight: false
              });
              onSuccess?.();
            },
            error: () => {
              patchState(state, {
                resetPasswordInflight: false
              });
              onFail?.();
            }
          })
        )
      )
    )
  );
}

export function initiateGoogleAuthFlow() {
  const clientId =
    '720670872677-9cmqdu0363dd5e052scd1fjvnr60k96u.apps.googleusercontent.com';
  const redirectUri = encodeURIComponent(
    `${window.location.href.split('?')[0]}`
  );
  const scope = encodeURIComponent(
    'https://www.googleapis.com/auth/calendar.events'
  );
  const responseType = 'code';
  const accessType = 'offline';
  const prompt = 'consent';
  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&access_type=${accessType}&prompt=${prompt}&state=google`;
  window.open(authUrl, '_self');
}

export function initiateZoomAuthFlow() {
  const clientId = environment.production
    ? '_MBVO6JYQUyeEcojZlWalQ'
    : 'yAfQT62lS74rD4nPrQpyg';
  const url = `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${
    window.location.href.split('?')[0]
  }&state=zoom`;
  window.open(url, '_self');
}

export function initiateMicrosoftAuthFlow(scopes: string) {
  const clientId = 'e3c8b73a-b602-435d-b1ce-1e28b6d99743';
  const redirectUri = encodeURIComponent(
    `${window.location.href.split('?')[0]}`
  );
  const requiredScopes = encodeURIComponent(scopes);
  const uniqueStateValue = encodeURIComponent(randomString(16));

  const codeVerifier = randomString(128);
  sessionStorage.setItem('code_verifier', codeVerifier);
  sessionStorage.setItem('microsoft_scopes', scopes);

  generateSHA256Base64(codeVerifier).then((codeVerifierHash) => {
    const codeChallenge = codeVerifierHash
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');

    const authorizationUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${requiredScopes}&state=${uniqueStateValue}&code_challenge=${codeChallenge}&code_challenge_method=S256`;

    window.open(authorizationUrl, '_self');
  });
}

function _buildUpdatePayload(
  payload: UpdateUserPayload | UpdateOrgPayload,
  dataObjName: 'updateUser' | 'organization',
  pictureObjName?: 'profilePicture' | 'logo',
  profilePicture?: File
): FormData {
  const payloadString = JSON.stringify(payload);

  const formData = new FormData();
  const blob = new Blob([payloadString], { type: 'application/json' });
  formData.append(dataObjName, blob);

  if (profilePicture && pictureObjName) {
    formData.append(pictureObjName, profilePicture);
  }

  return formData;
}

// import { SHA256, enc } from 'crypto-js';
function randomString(length: number) {
  const randomChars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
}

async function generateSHA256Base64(codeVerifier: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const base64String = btoa(String.fromCharCode.apply(null, hashArray));
  return base64String;
}
