import { AxleToastService } from './shared/services/toast/axle-toast.service';
import { FeatureFlag } from './shared/services/user/user.models';
import { UserStore } from './shared/services/user/user.state';
import { ThemeStore } from './shared/state/theme/theme.state';
import { DOCUMENT } from '@angular/common';
import {
  APP_INITIALIZER,
  ChangeDetectionStrategy,
  Component,
  ErrorHandler,
  OnDestroy,
  OnInit,
  Renderer2,
  effect,
  inject
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationSkipped,
  NavigationStart,
  Router,
  RouterOutlet
} from '@angular/router';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { TraceService, createErrorHandler } from '@sentry/angular-ivy';
import { EditorModule } from '@tinymce/tinymce-angular';
import posthog from 'posthog-js';
import { debounceTime, map } from 'rxjs';

if (
  !window.location.host.includes('127.0.0.1') &&
  !window.location.host.includes('localhost')
) {
  posthog.init('phc_B44LvJW3JBsMdNAM5rjNBXg9iCml1SACjcMsKhU6ve0', {
    api_host: 'https://app.posthog.com',
    secure_cookie: true
  });
}

@Component({
  selector: 'axle-root',
  standalone: true,
  imports: [RouterOutlet, EditorModule],
  providers: [
    AuthHttpInterceptor,
    {
      provide: TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: true
      })
    }
  ],
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  toastService = inject(AxleToastService);
  private _themeStore = inject(ThemeStore);
  private _renderer = inject(Renderer2);
  private _userStore = inject(UserStore);
  private _router = inject(Router);
  private _document: Document = inject(DOCUMENT);

  currentTheme = this._themeStore.theme;
  maintenanceMode = this._userStore.maintenanceModeEnabled;

  showProgressBar = toSignal(
    this._router.events.pipe(
      debounceTime(300),
      map((event) => {
        if (event instanceof NavigationStart) {
          return true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError ||
          event instanceof NavigationSkipped
        ) {
          return false;
        } else {
          return true;
        }
      })
    )
  );

  constructor() {
    effect(() => {
      if (this.maintenanceMode()) {
        this.applyMaintenanceBackgroundClasses();
      } else {
        this.removeMaintenanceBackgroundClasses();
        this.removeBackgroundClasses();
        this._renderer.addClass(
          this._document.body,
          this.currentTheme().bgLight
        );
      }
    });
  }

  ngOnInit() {
    posthog.onFeatureFlags((flags) => {
      this._userStore.storeFeatureFlags(new Set(flags as FeatureFlag[]));
    });

    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  ngOnDestroy() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    );
  }

  applyBackgroundClasses() {
    const classes =
      'animate-bgGrad bg-400% bg-gradient-to-r from-primary-300 via-green-300 to-organge-300';
    const classArray = classes.split(' ');

    classArray.forEach((className) =>
      this._renderer.addClass(this._document.body, className)
    );
  }

  applyMaintenanceBackgroundClasses() {
    const classes =
      'animate-bgGrad bg-400% bg-gradient-to-r from-orange-300 via-amber-300 to-yellow-300';
    const classArray = classes.split(' ');

    classArray.forEach((className) =>
      this._renderer.addClass(this._document.body, className)
    );
  }

  removeMaintenanceBackgroundClasses() {
    const classes =
      'animate-bgGrad bg-400% bg-gradient-to-r from-orange-300 via-amber-300 to-yellow-300';
    const classArray = classes.split(' ');

    classArray.forEach((className) =>
      this._renderer.removeClass(this._document.body, className)
    );
  }

  removeBackgroundClasses() {
    const classes =
      'animate-bgGrad bg-400% bg-gradient-to-r from-primary-300 via-green-300 to-organge-300';
    const classArray = classes.split(' ');

    classArray.forEach((className) =>
      this._renderer.removeClass(this._document.body, className)
    );
  }
  //   this._renderer.addClass(this._document.body, this.currentTheme().bgDark);

  //   const themePreference = localStorage.getItem('axle-theme-preference');

  //   if (themePreference === 'dark' || themePreference === 'light') {
  //     this._themeStore.updateDarkMode(themePreference === 'dark');

  //     if (themePreference === 'dark') {
  //       this._document.documentElement.classList.add('dark');
  //     } else {
  //       this._document.documentElement.classList.remove('dark');
  //     }
  //   } else {
  //     const event = window.matchMedia('(prefers-color-scheme: dark)');

  //     if (event) {
  //       this._themeStore.updateDarkMode(event.matches);
  //       localStorage.setItem(
  //         'axle-theme-preference',
  //         event.matches ? 'dark' : 'light'
  //       );

  //       if (event.matches) {
  //         this._document.documentElement.classList.add('dark');
  //       } else {
  //         this._document.documentElement.classList.remove('dark');
  //       }
  //     }
  //   }

  //   window
  //     .matchMedia('(prefers-color-scheme: dark)')
  //     .addEventListener('change', (event) => {
  //       this._themeStore.updateDarkMode(event.matches);
  //       localStorage.setItem(
  //         'axle-theme-preference',
  //         event.matches ? 'dark' : 'light'
  //       );

  //       if (event.matches) {
  //         this._document.documentElement.classList.add('dark');
  //       } else {
  //         this._document.documentElement.classList.remove('dark');
  //       }
  //     });

  removeToast(index: number) {
    this.toastService.remove(index);
  }

  chatButtonClicked() {}

  private handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      posthog.reloadFeatureFlags();
    }
  };
}
