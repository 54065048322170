import { MonoTypeOperatorFunction } from 'rxjs';
import { timeout } from 'rxjs/operators';

/**
 * Custom RxJS operator to apply a timeout to an observable with a customizable duration and error message.
 * @param methodName Name of method that timed out.
 * @param duration Timeout duration in milliseconds.
 */
export function httpTimeout<T>(
  methodName: string = 'method',
  duration: number = 30000
): MonoTypeOperatorFunction<T> {
  return timeout({
    each: duration,
    with: () => {
      throw new Error(`${methodName} timed out`);
    }
  });
}
