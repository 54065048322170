import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateFn,
  CanDeactivate,
  CanDeactivateFn
} from '@angular/router';

import { Observable, of, tap } from 'rxjs';

import { AxleEmailCampaignManagerContainerComponent } from 'src/app/features/email/campaign-manager/container/axle-email-campaign-manager-container.component';
import { isDefault } from 'src/app/features/email/campaign-manager/state/axle-email-campaign-manager-state.constants';
import { EmailCampaignManagerContainerStore } from 'src/app/features/email/campaign-manager/state/axle-email-campaign-manager.state';
import { AxleEmailContainerComponent } from 'src/app/features/email/container/axle-email-container.component';

import { EmailStore } from '../services/email/email.state';
import { AxleCampaignDraftsPaginatorService } from '../services/email/paginator/campaign-drafts.paginator.service';
import { AxleSentCampaignsPaginatorService } from '../services/email/paginator/sent-campaigns.paginator.service';
import { AxleEventsHomePaginatorService } from '../services/events/paginator/events-home-paginator.service';
import { EventsStore } from '../services/events/state/events.state';

@Injectable({
  providedIn: 'root'
})
export class CampaignResetGuard implements CanActivate {
  private _store = inject(EmailCampaignManagerContainerStore);

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return of(true).pipe(
      tap(() => {
        this._store.reset({
          eventId: route.queryParams['eventId'] || null
        });
      })
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class CampaignAutoSaveGuard
  implements CanDeactivate<AxleEmailCampaignManagerContainerComponent>
{
  private _emailStore = inject(EmailStore);
  private _campaignStore = inject(EmailCampaignManagerContainerStore);

  canDeactivate(): Observable<boolean> {
    return new Observable((observer) => {
      if (this._campaignStore.selectedCampaign()?.status === 'COMPLETED') {
        observer.next(true);
        observer.complete();
        return;
      }

      const payload = this._campaignStore.campaignPayload();
      if (isDefault(payload)) {
        observer.next(true);
        observer.complete();
        return;
      }

      this._emailStore.upsertCampaign({
        payload,
        onSuccess: () => {
          this._campaignStore.reset();
          observer.next(true);
          observer.complete();
        },
        onFail: () => {
          observer.next(true);
          observer.complete();
        }
      });
    });
  }
}

export const clearEmailStore: CanDeactivateFn<
  AxleEmailContainerComponent
> = () => {
  const emailStore = inject(EmailStore);
  const draftPaginatorService = inject(AxleCampaignDraftsPaginatorService);
  const sentPaginatorService = inject(AxleSentCampaignsPaginatorService);

  emailStore.reset();
  draftPaginatorService.reset();
  sentPaginatorService.reset();

  return true;
};

export const fetchEventDetails: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  const eventsStore = inject(EventsStore);
  const eventsPaginator = inject(AxleEventsHomePaginatorService);

  const eventId = route.queryParams?.['eventId'];
  if (
    eventId &&
    !eventsStore.strictEventsEntityMap()?.[eventId] &&
    !eventsStore.fetchLightEventsInflight()
  ) {
    eventsPaginator.fetchEvents('NEW', 0, {
      eventIds: [eventId]
    });
  }

  return true;
};
