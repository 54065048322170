import { Injectable, signal } from '@angular/core';

export type ToastType = 'success' | 'error' | 'info';

export interface Toast {
  message: string;
  duration: number;
  type: ToastType;
}

@Injectable({
  providedIn: 'root'
})
export class AxleToastService {
  toasts = signal<Toast[]>([]);

  private addToast(toast: Toast) {
    this.toasts.update((toasts) => {
      const updatedToasts = toasts.length >= 2 ? toasts.slice(-1) : toasts;
      return [...updatedToasts, toast];
    });

    setTimeout(() => this.remove(0), toast.duration);
  }

  addError(message: string, duration: number = 4000) {
    const toast: Toast = { message, duration, type: 'error' };
    this.addToast(toast);
  }

  addSuccess(message: string, duration: number = 4000) {
    const toast: Toast = { message, duration, type: 'success' };
    this.addToast(toast);
  }

  remove(i: number) {
    this.toasts.update((toasts) =>
      [...toasts].filter((_, index) => index !== i)
    );
  }
}
