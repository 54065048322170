import { ErrorResponse, RxMethod } from '../models/rxMethod.models';
import { httpTimeout } from '../operators/http.operators';
import { HttpErrorResponse } from '@angular/common/http';
import { tapResponse } from '@ngrx/operators';
import { Observable, take } from 'rxjs';

export function logoutRequired(error: unknown) {
  if (error instanceof HttpErrorResponse && error.url?.includes('axle')) {
    return (
      (error?.status === 400 && [102, 126].includes(error?.error?.errorCode)) ||
      error?.status === 403
    );
  }

  return false;
}

export function handleHttpResponse<T>(
  source$: Observable<T>,
  method: string,
  logout: RxMethod<void>,
  timeout?: number
): Observable<T> {
  return source$.pipe(
    take(1),
    tapResponse({
      next: (response) => response,
      error: (error) => {
        if (logoutRequired(error)) {
          logout();
        }
        if ((error as HttpErrorResponse).error?.errorCode) {
          throw (error as HttpErrorResponse).error;
        } else if (error) {
          throw {
            ...(error as HttpErrorResponse),
            extraInformation: null,
            errorCode: 0
          } as ErrorResponse;
        } else {
          throw new Error(`${method} error`);
        }
      }
    }),
    httpTimeout(method, timeout ?? 10000)
  );
}

export function handlePublicHttpResponse<T>(
  source$: Observable<T>,
  method: string,
  timeout?: number
): Observable<T> {
  return source$.pipe(
    take(1),
    tapResponse({
      next: (response) => response,
      error: (error) => {
        if ((error as HttpErrorResponse).error?.errorCode) {
          throw (error as HttpErrorResponse).error;
        } else if (error) {
          throw {
            ...(error as HttpErrorResponse),
            extraInformation: null,
            errorCode: 0
          } as ErrorResponse;
        } else {
          throw new Error(`${method} error`);
        }
      }
    }),
    httpTimeout(method, timeout ?? 10000)
  );
}

export function downloadFile(blob: Blob, feature: string) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${feature}-export_${new Date().toISOString()}.xlsx`;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function downloadFileUrl(url: string) {
  const link = document.createElement('a');
  link.href = url;
  link.download = url;
  link.style.display = 'none';
  link.target = '_blank';
  link.rel = 'noopener noreferrer';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function slugify(text: string): string {
  return text
    .toLowerCase()
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/--+/g, '-')
    .trim();
}
