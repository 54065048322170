import { withDevtools } from '../../functions/redux-dev-tools';
import { axleStringCompare } from '../../functions/string.function';
import {
  isClientPortal,
  showRefreshMessage,
  sortedFullPossibleAttendees,
  sortedPossibleAttendees,
  sortedPossibleHosts
} from './user.computed';
import { clearedUserStore, defaultUserState } from './user.constants';
import {
  connectGoogle,
  connectOutlook,
  connectZoom,
  deactivateUser,
  getOrgInfo,
  getOrgInfoWithEmail,
  logoutIntegration,
  promoteUser,
  refreshVerifiedEmailStatus,
  resetPassword,
  updateOrg,
  updateUser,
  validateUserEmail
} from './user.methods';
import {
  FeatureFlag,
  OrgInfoResponse,
  PossibleAttendee,
  UserInfo,
  UserInfoResponse
} from './user.models';
import { UserService } from './user.service';
import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  type,
  withComputed,
  withMethods,
  withState
} from '@ngrx/signals';
import {
  entityConfig,
  setEntities,
  withEntities
} from '@ngrx/signals/entities';

export interface UserState {
  updateUserInflight: boolean;
  getOrgInfoInflight: boolean;
  getOrgInfoWithEmailInflight: boolean;
  updateOrgInflight: boolean;
  validateEmailInflight: boolean;
  getPendingApprovalUsersInflight: boolean;
  approveUserInflight: boolean;
  promoteUserInflight: boolean;
  deactivateUserInflight: boolean;
  connectGoogleInflight: boolean;
  connectZoomInflight: boolean;
  connectOutlookInflight: boolean;
  deactivateIntegrationInflight: boolean;
  refreshEmailValidationStatusInflight: boolean;
  userInfo: UserInfo | null;
  orgInfo: OrgInfoResponse | null;
  error: boolean;
  resetPasswordInflight: boolean;
  enabledFeatureFlags: Set<FeatureFlag>;
}

export const possibleAttendeesCollection = entityConfig({
  entity: type<PossibleAttendee>(),
  selectId: (attendee) => attendee.userId,
  collection: 'possible-attendees'
});

export const UserStore = signalStore(
  { providedIn: 'root' },
  withDevtools('user'),
  withState<UserState>(defaultUserState),
  withEntities(possibleAttendeesCollection),
  withComputed((store) => ({
    orgId: computed(() => store.userInfo()?.organization.id),
    possibleAttendees: sortedPossibleAttendees(
      store['possible-attendeesEntities']
    ),
    fullPossibleAttendees: sortedFullPossibleAttendees(
      store['possible-attendeesEntities']
    ),
    possibleHosts: sortedPossibleHosts(store['possible-attendeesEntities']),
    showRefreshMessage: showRefreshMessage(store.enabledFeatureFlags),
    hasIntegration: computed(() => {
      const integrations = store.userInfo()?.integrations;
      if (!integrations) {
        return false;
      }
      return integrations.google || integrations.outlook;
    }),
    fullName: computed(() => {
      const userInfo = store.userInfo();
      return userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : '';
    }),
    profilePic: computed(() => store.userInfo()?.profilePictureUrl),
    coverageOnlyUserIds: computed(
      () =>
        new Set(
          store['possible-attendeesEntities']()
            .filter(({ visibilityType }) => visibilityType === 'COVERAGE_ONLY')
            .map(({ userId }) => userId)
        )
    ),
    coverageOnlyUsers: computed(() =>
      store['possible-attendeesEntities']().filter(
        ({ visibilityType }) => visibilityType === 'COVERAGE_ONLY'
      )
    ),
    researchAndSalesColleagues: computed(() =>
      store['possible-attendeesEntities']()
        .filter(
          ({ roles }) => roles.includes('SALES') || roles.includes('RESEARCH')
        )
        .sort((a, b) =>
          axleStringCompare(
            `${a.lastName}${a.firstName}`,
            `${b.lastName}${b.firstName}`
          )
        )
    ),
    maintenanceModeEnabled: computed(() =>
      store.enabledFeatureFlags().has('axle_banners_maintenance')
    ),
    isClientPortal: computed(() =>
      isClientPortal(store.enabledFeatureFlags, store.userInfo)()
    ),
    currentUserAsPossibleAttendee: computed(() => {
      const userId = store.userInfo()?.id;
      return userId ? store['possible-attendeesEntityMap']()[userId] : null;
    }),
    allocationManagerEnabled: computed(() => {
      const roleTypes = store.userInfo()?.roleTypes;
      if (!roleTypes) {
        return false;
      }

      if (roleTypes.includes('CORPORATE_ACCESS')) {
        return true;
      }

      return store.enabledFeatureFlags().has('axle_allocation_manager_enabled');
    }),
    isSales: computed(() => store.userInfo()?.roleTypes.includes('SALES')),
    isResearch: computed(() =>
      store.userInfo()?.roleTypes.includes('RESEARCH')
    ),
    isCorporateAccess: computed(() =>
      store.userInfo()?.roleTypes?.includes('CORPORATE_ACCESS')
    ),
    isMarketing: computed(() =>
      store.userInfo()?.roleTypes?.includes('MARKETING')
    ),
    isAdmin: computed(() => store.userInfo()?.roleTypes.includes('ADMIN'))
  })),
  withMethods((store) => {
    const userService = inject(UserService);

    return {
      updateUser: updateUser(userService, store),
      validateUserEmail: validateUserEmail(userService, store, store.userInfo),
      getOrgInfo: getOrgInfo(userService, store),
      updateOrg: updateOrg(userService, store),
      promoteUser: promoteUser(userService, store),
      deactivateUser: deactivateUser(userService, store),
      connectGoogle: connectGoogle(userService, store, store.userInfo),
      connectOutlook: connectOutlook(userService, store, store.userInfo),
      connectZoom: connectZoom(userService, store, store.userInfo),
      deactivateIntegration: logoutIntegration(
        userService,
        store,
        store.userInfo
      ),
      resetPassword: resetPassword(userService, store),
      refreshVerifiedEmailStatus: refreshVerifiedEmailStatus(
        userService,
        store,
        store.userInfo
      ),
      getOrgInfoWithEmail: getOrgInfoWithEmail(userService, store),
      setInitialStore: (response: UserInfoResponse) => {
        patchState(
          store,
          setEntities(response.possibleAttendees, possibleAttendeesCollection)
        );
        patchState(store, {
          userInfo: { ...response, timestamp: new Date() }
        });
      },
      storeFeatureFlags: (enabledFeatureFlags: Set<FeatureFlag>) =>
        patchState(store, { enabledFeatureFlags }),
      destroy: () => patchState(store, clearedUserStore)
    };
  })
);
