import { UserState } from './user.state';

export const clearedUserStore = {
  updateUserInflight: undefined,
  getOrgInfoInflight: undefined,
  updateOrgInflight: undefined,
  getOrgInfoWithEmailInflight: undefined,
  userInfo: null,
  error: undefined
};

export const defaultUserState: UserState = {
  updateUserInflight: false,
  getOrgInfoInflight: false,
  updateOrgInflight: false,
  validateEmailInflight: false,
  getPendingApprovalUsersInflight: false,
  approveUserInflight: false,
  promoteUserInflight: false,
  deactivateUserInflight: false,
  connectGoogleInflight: false,
  connectOutlookInflight: false,
  connectZoomInflight: false,
  deactivateIntegrationInflight: false,
  refreshEmailValidationStatusInflight: false,
  userInfo: null,
  orgInfo: null,
  error: false,
  resetPasswordInflight: false,
  enabledFeatureFlags: new Set(),
  getOrgInfoWithEmailInflight: false
};
