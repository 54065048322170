@if (showProgressBar()) {
  <div
    class="fixed left-0 top-0 z-50 h-0.5 w-full bg-primary-200"
    role="status"
    aria-live="polite"
    aria-label="Loading content">
    <div class="h-full animate-progress bg-primary-600"></div>
  </div>
}

@if (maintenanceMode()) {
  <div
    class="fixed right-2 top-2 z-50 flex items-center gap-3 rounded-xl bg-orange-50 px-3 py-2 shadow-axlePrimary">
    <i class="bi bi-exclamation-triangle-fill text-orange-500"></i>
    <p class="text-xs font-light">
      We are doing some maintenance. Please come back later when things are all
      set!
    </p>
  </div>
}

<router-outlet />

<!-- Toast Container -->
<div
  class="fixed left-1/2 top-2.5 z-[2147482000] flex max-w-[650px] -translate-x-1/2 transform flex-col gap-2 rounded-xl shadow-axleBlue">
  @for (toast of toastService.toasts(); track $index) {
    <div
      [attr.role]="toast.type === 'error' ? 'alert' : 'status'"
      [attr.aria-live]="toast.type === 'error' ? 'assertive' : 'polite'"
      class="axle-bg-white flex animate-enter items-center gap-3.5 rounded-xl px-4 py-2 shadow">
      @if (toast.type === 'success') {
        <i
          class="bi bi-check-circle-fill text-lg text-green-500"
          aria-hidden="true"></i>
      } @else if (toast.type === 'error') {
        <i
          class="bi bi-x-circle-fill text-lg text-error-500"
          aria-hidden="true"></i>
      } @else if (toast.type === 'info') {
        <i
          class="bi bi-info-circle-fill text-lg text-primary-600"
          aria-hidden="true"></i>
      }
      <p class="axle-text-secondary-900 text-sm">{{ toast.message }}</p>
      <button
        type="button"
        (click)="removeToast($index)"
        class="-mx-1.5 -my-1.5 ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg p-1.5 text-secondary-500 hover:text-secondary-900 dark:text-secondary-500 dark:hover:text-white"
        aria-label="Close">
        <span class="sr-only">Close</span>
        <i class="bi bi-x text-xl"></i>
      </button>
    </div>
  }
</div>
