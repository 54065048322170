import {
  HttpParams,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationRef,
  ErrorHandler,
  inject
} from '@angular/core';
import {
  bootstrapApplication,
  enableDebugTools
} from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  RedirectFunction,
  Router,
  Routes,
  provideRouter,
  withComponentInputBinding,
  withViewTransitions
} from '@angular/router';

import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { provideTransloco } from '@ngneat/transloco';
import {
  browserTracingIntegration,
  createErrorHandler,
  init
} from '@sentry/angular-ivy';

import { AppComponent } from './app/app.component';
import { Action } from './app/shared/functions/redux-dev-tools';
import {
  determineAuthRedirectTo,
  determineRedirectTo,
  isAdmin,
  parentAuthGuardFn
} from './app/shared/guard/authentication.guards';
import { whitelistInterceptorFn } from './app/shared/interceptors/shared-interceptors';
import { GoogleMapsLoaderService } from './app/shared/services/google-maps/google-maps-loader.service';
import { UserStore } from './app/shared/services/user/user.state';
import { TranslocoHttpLoader } from './app/transloco-loader';
import { environment } from './environments/environment';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any;
    __REDUX_DEVTOOLS_EXTENSION__:
      | {
          connect: (options: { name: string }) => {
            send: (action: Action, state: Record<string, unknown>) => void;
          };
        }
      | undefined;
  }
}

export const redirectWithParams =
  (redirectPath: string): RedirectFunction =>
  ({ params = {}, queryParams = {} }) => {
    try {
      const resolvedPath = Object.entries(params).reduce(
        (path, [key, value]) =>
          path.replace(`:${key}`, encodeURIComponent(value ?? '')),
        redirectPath
      );

      return Object.keys(queryParams).length
        ? `${resolvedPath}?${new HttpParams({ fromObject: queryParams }).toString()}`
        : resolvedPath;
    } catch (e) {
      return redirectPath;
    }
  };

export function initializeGoogleMaps(
  googleMapsLoaderService: GoogleMapsLoaderService
) {
  return (): Promise<void> => {
    return googleMapsLoaderService.loadScript();
  };
}

const routes: Routes = [
  {
    path: 'auth',
    children: [],
    // component: SpinnerOverlayComponent,
    canActivate: [determineAuthRedirectTo]
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./app/features/login/login/login.component').then(
        (c) => c.AxleLoginComponent
      )
  },
  {
    path: 'password',
    children: [
      {
        path: 'reset',
        loadComponent: () =>
          import(
            './app/features/reset-password/container/reset-password-container.component'
          ).then((c) => c.AxleResetPasswordContainerComponent)
      }
    ]
  },
  {
    path: 'public',
    children: [
      {
        path: 'events',
        children: [
          {
            path: 'corporate/:id',
            redirectTo: redirectWithParams('/public/events/:id')
          },
          {
            path: ':id',
            loadComponent: () =>
              import(
                './app/features/public/container/axle-public-container.component'
              ).then((c) => c.AxlePublicContainerComponent),
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './app/features/public/overview/axle-public-overview.component'
                  ).then((c) => c.AxlePublicOverviewComponent)
              },
              {
                path: 'additionalInfo',
                loadComponent: () =>
                  import(
                    './app/features/public/additional-info/registration/axle-public-registration-additional-info.component'
                  ).then((c) => c.AxlePublicAdditionalInfoRegistrationComponent)
              },
              {
                path: 'alreadyRegistered',
                loadComponent: () =>
                  import(
                    './app/features/public/already-registered/axle-public-already-registered.component'
                  ).then((c) => c.AxlePublicAlreadyRegisteredComponent)
              },
              {
                path: 'attendees',
                children: [
                  {
                    path: '',
                    loadComponent: () =>
                      import(
                        './app/features/public/attendees/axle-public-attendees-container.component'
                      ).then((c) => c.AxlePublicAttendeesContainerComponent),
                    children: [
                      {
                        path: '',
                        loadComponent: () =>
                          import(
                            './app/features/public/attendees/tables/attendee-table/axle-public-attendees-table.component'
                          ).then((c) => c.AxlePublicAttendeesTableComponent)
                      },
                      {
                        path: 'requests',
                        loadComponent: () =>
                          import(
                            './app/features/public/attendees/tables/requests-table/axle-public-requests-table.component'
                          ).then((c) => c.AxlePublicRequestsTableComponent)
                      }
                    ]
                  }
                ]
              },
              {
                path: 'availability',
                loadComponent: () =>
                  import(
                    './app/features/public/availability/axle-public-availability.component'
                  ).then((c) => c.AxlePublicAvailabilityComponent)
              },
              {
                path: 'updateAvailability',
                loadComponent: () =>
                  import(
                    './app/features/public/availability/axle-public-availability.component'
                  ).then((c) => c.AxlePublicAvailabilityComponent),
                data: { mode: 'update' }
              },
              {
                path: 'corporateRegistration',
                loadComponent: () =>
                  import(
                    './app/features/public/corporate-registration/axle-public-corporate-registration.component'
                  ).then((c) => c.AxlePublicCorporateRegistrationComponent)
              },
              {
                path: 'eventNotFound',
                loadComponent: () =>
                  import(
                    './app/features/public/event-not-found/axle-public-event-not-found.component'
                  ).then((c) => c.AxlePublicEventNotFoundComponent)
              },
              {
                path: 'hosts',
                loadComponent: () =>
                  import(
                    './app/features/public/hosts/axle-public-hosts-container.component'
                  ).then((c) => c.AxlePublicHostsContainerComponent)
              },
              {
                path: 'requests',
                loadComponent: () =>
                  import(
                    './app/features/public/corporate-requests-container/axle-corporate-requests.component'
                  ).then((c) => c.AxleCorporateRequestsContainerComponent),
                children: [
                  {
                    path: '',
                    loadComponent: () =>
                      import(
                        './app/features/public/corporate-requests-container/view-requests/view-requests.component'
                      ).then((c) => c.ViewRequestsComponent)
                  },
                  {
                    path: 'approve',
                    loadComponent: () =>
                      import(
                        './app/features/public/corporate-requests-container/approve-schedules/approve-schedules.component'
                      ).then((c) => c.ApproveSchedulesComponent)
                  }
                ]
              },
              {
                path: 'meetings',
                loadComponent: () =>
                  import(
                    './app/features/public/meetings/axle-public-meetings.component'
                  ).then((c) => c.AxlePublicMeetingsComponent)
              },
              {
                path: 'participation',
                loadComponent: () =>
                  import(
                    './app/features/public/corporate-participation/axle-public-participation.component'
                  ).then((c) => c.AxlePublicParticipationComponent)
              },
              {
                path: 'presentations',
                loadComponent: () =>
                  import(
                    './app/features/public/presentations/container/axle-public-presentations-container.component'
                  ).then((c) => c.AxlePublicPresentationsContainerComponent)
              },
              {
                path: 'questionnaire',
                loadComponent: () =>
                  import(
                    './app/features/public/questionnaire/axle-public-questionnaire.component'
                  ).then((c) => c.AxlePublicQuestionnaireComponent)
              },
              {
                path: 'rsvp',
                children: [
                  {
                    path: 'questionnaire',
                    children: [
                      {
                        path: '',
                        loadComponent: () =>
                          import(
                            './app/features/public/questionnaire/rsvp/axle-rsvp-questionnaire.component'
                          ).then((c) => c.AxleRsvpQuestionnaireComponent)
                      },
                      {
                        path: 'update',
                        loadComponent: () =>
                          import(
                            './app/features/public/questionnaire/rsvp/axle-rsvp-questionnaire.component'
                          ).then((c) => c.AxleRsvpQuestionnaireComponent),
                        data: { mode: 'update' }
                      }
                    ]
                  },
                  {
                    path: 'additionalInfo',
                    loadComponent: () =>
                      import(
                        './app/features/public/additional-info/rsvp/axle-public-rsvp-additional-info.component'
                      ).then((c) => c.AxlePublicAdditionalInfoRsvpComponent)
                  },
                  {
                    path: 'success',
                    loadComponent: () =>
                      import(
                        './app/features/public/rsvp-success/axle-rsvp-success.component'
                      ).then((c) => c.AxleRsvpSuccessComponent)
                  }
                ]
              },
              {
                path: 'updateQuestionnaire',
                loadComponent: () =>
                  import(
                    './app/features/public/questionnaire/axle-public-questionnaire.component'
                  ).then((c) => c.AxlePublicQuestionnaireComponent),
                data: { mode: 'update' }
              },
              {
                path: 'registerColleague',
                loadComponent: () =>
                  import(
                    './app/features/public/register-colleague/axle-register-colleague.component'
                  ).then((c) => c.AxleRegisterColleagueComponent)
              },
              {
                path: 'registerNewColleague',
                loadComponent: () =>
                  import(
                    './app/features/public/register-new-colleague/axle-register-new-colleague.component'
                  ).then((c) => c.AxleRegisterNewColleagueComponent)
              },
              {
                path: 'registrationSuccess',
                loadComponent: () =>
                  import(
                    './app/features/public/registration-success/axle-registration-success.component'
                  ).then((c) => c.AxleRegistrationSuccessComponent)
              },
              {
                path: 'requestedOrgs',
                loadComponent: () =>
                  import(
                    './app/features/public/requested-orgs/axle-public-requested-orgs.component'
                  ).then((c) => c.AxlePublicRequestedOrgsComponent)
              },
              {
                path: 'updateRequestedOrgs',
                loadComponent: () =>
                  import(
                    './app/features/public/requested-orgs/axle-public-requested-orgs.component'
                  ).then((c) => c.AxlePublicRequestedOrgsComponent),
                data: { mode: 'update' }
              },
              {
                path: 'selectMeeting',
                loadComponent: () =>
                  import(
                    './app/features/public/select-meeting-time/axle-public-select-meeting-time.component'
                  ).then((c) => c.AxlePublicSelectMeetingTimeComponent)
              },
              {
                path: 'updateSelectMeeting',
                loadComponent: () =>
                  import(
                    './app/features/public/select-meeting-time/axle-public-select-meeting-time.component'
                  ).then((c) => c.AxlePublicSelectMeetingTimeComponent),
                data: { mode: 'update' }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '',
    loadComponent: () =>
      import(
        './app/shared/components/containers/auth-container/auth-container.component'
      ).then((c) => c.AxleAuthContainerComponent),
    children: [
      {
        path: 'admin-v777rpNsg_5hjfsdkhjsdfkhjsdfkjhsdfmuegcko6F4w',
        canActivate: [isAdmin],
        loadComponent: () =>
          import(
            './app/features/admin/admin-container/admin-container.component'
          ).then((c) => c.AxleAdminContainerComponent)
      },
      {
        path: 'home',
        loadComponent: () =>
          import('./app/features/home/container/home-container.component').then(
            (c) => c.AxleHomeContainerComponent
          ),
        children: [
          {
            path: 'uploadMeetings',
            loadComponent: () =>
              import(
                './app/features/upload-meetings/upload-container/upload-meetings-container.component'
              ).then((c) => c.AxleUploadMeetingsComponent)
          },
          {
            path: '',
            loadComponent: () =>
              import(
                './app/features/home/primary-container/home-primary-container.component'
              ).then((c) => c.AxleHomePrimaryContainerComponent)
          }
        ]
      },
      {
        path: 'contacts',
        loadComponent: () =>
          import(
            './app/features/contacts/container/axle-contacts-container.component'
          ).then((c) => c.AxleContactsContainerComponent)
      },
      {
        path: 'events',
        loadComponent: () =>
          import(
            './app/features/events/container/events-container.component'
          ).then((c) => c.AxleEventsContainerComponent),
        canActivate: [
          () => {
            const router = inject(Router);
            const userStore = inject(UserStore);

            if (userStore.isClientPortal()) {
              router.navigate(['home']);
              return false;
            }

            return true;
          }
        ],
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './app/features/events/home/overview/axle-events-overview.component'
              ).then((c) => c.AxleEventsOverviewComponent)
          },
          {
            path: 'create',
            loadComponent: () =>
              import(
                './app/features/events/create/container/create-event-container.component'
              ).then((c) => c.AxleCreateEventContainerComponent),
            data: { mode: 'CREATE' }
          },
          {
            path: ':id',
            loadComponent: () =>
              import(
                './app/features/events/details/container/events-details.component'
              ).then((c) => c.AxleEventsDetailsComponent),
            children: [
              {
                path: 'update',
                loadComponent: () =>
                  import(
                    './app/features/events/create/container/create-event-container.component'
                  ).then((c) => c.AxleCreateEventContainerComponent),
                data: { mode: 'UPDATE' }
              },
              {
                path: 'targetList',
                loadComponent: () =>
                  import(
                    './app/features/events/target-list/target-list.component'
                  ).then((c) => c.AxleTargetListComponent)
              },
              {
                path: 'portalPreferences',
                loadComponent: () =>
                  import(
                    './app/features/events/preferences/request/axle-event-request-preferences.component'
                  ).then((c) => c.AxleEventRequestPreferencesComponent)
              },
              {
                path: 'questionnaireBuilder',
                loadComponent: () =>
                  import(
                    './app/features/events/questionnaire-container/axle-events-questionnaire-container.component'
                  ).then((c) => c.AxleEventsQuestionnaireContainerComponent)
              },
              {
                path: 'generalInterestList',
                loadComponent: () =>
                  import(
                    './app/features/events/general-interest-list/axle-general-interest-list.component'
                  ).then((c) => c.AxleGeneralInterestListComponent)
              },
              {
                path: 'invitePreferences',
                loadComponent: () =>
                  import(
                    './app/features/events/preferences/invite/axle-event-invite-preferences.component'
                  ).then((c) => c.AxleEventInvitePreferencesComponent)
              },
              {
                path: 'itinerary',
                loadComponent: () =>
                  import(
                    './app/features/events/itinerary/container/axle-event-itinerary-container.component'
                  ).then((c) => c.AxleEventItineraryContainerComponent),
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: 'org'
                  },
                  {
                    path: 'org',
                    loadComponent: () =>
                      import(
                        './app/features/events/itinerary/org-level/event-org-itinerary.component'
                      ).then((c) => c.AxleEventOrgItineraryComponent),
                    data: { feature: 'ORG_LEVEL' }
                  },
                  {
                    path: 'event',
                    loadComponent: () =>
                      import(
                        './app/features/events/itinerary/event-level/event-level-itinerary.component'
                      ).then((c) => c.AxleEventLevelItineraryComponent),
                    data: { feature: 'EVENT_LEVEL' }
                  },
                  {
                    path: 'presentations',
                    loadComponent: () =>
                      import(
                        './app/features/events/presentations/container/axle-event-presentations.component'
                      ).then((c) => c.AxleEventPresentationsComponent),
                    data: { feature: 'PRESENTATIONS' }
                  },
                  {
                    path: 'rooms',
                    loadComponent: () =>
                      import(
                        './app/features/events/itinerary/rooms/axle-event-rooms.component'
                      ).then((c) => c.AxleEventRoomsComponent),
                    data: { feature: 'ROOMS' }
                  },
                  {
                    path: 'travel',
                    loadComponent: () =>
                      import(
                        './app/features/events/travel-logistics/container/axle-travel-logistics-container.component'
                      ).then((c) => c.AxleTravelLogisticsContainerComponent),
                    data: { feature: 'TRAVEL' }
                  }
                ]
              },
              {
                path: 'outreach',
                loadComponent: () =>
                  import(
                    './app/features/events/communications-manager/container/communications-manager.component'
                  ).then((c) => c.AxleCommunicationsManagerComponent)
              },
              {
                path: 'allocate',
                loadComponent: () =>
                  import(
                    './app/features/events/allocation-manager/container/allocation-manager.component'
                  ).then((c) => c.AxleAllocationManagerComponent)
              },
              {
                path: 'activityLog',
                loadComponent: () =>
                  import(
                    './app/features/events/activity-log/container/axle-activity-log-container.component'
                  ).then((c) => c.AxleActivityLogContainerComponent),
                children: [
                  {
                    path: 'eventChanges',
                    loadComponent: () =>
                      import(
                        './app/features/events/activity-log/event-changes/event-changes.component'
                      ).then((c) => c.AxleEventActivityLogComponent),
                    data: { feature: 'EVENT_CHANGES' }
                  },
                  {
                    path: 'requestUpdates',
                    loadComponent: () =>
                      import(
                        './app/features/events/activity-log/request-updates/axle-request-updates-container.component'
                      ).then((c) => c.AxleRequestUpdatesContainerComponent),
                    data: { feature: 'REQUEST_UPDATES' }
                  },
                  {
                    path: 'emailActivity',
                    loadComponent: () =>
                      import(
                        './app/features/events/activity-log/email-activity/axle-email-activity-container.component'
                      ).then((c) => c.AxleEmailActivityContainerComponent),
                    data: { feature: 'EMAIL_ACTIVITY' }
                  }
                ]
              },
              {
                path: '',
                loadComponent: () =>
                  import(
                    './app/features/events/details/main/axle-events-main-container-component.component'
                  ).then((c) => c.AxleEventDetailsMainContainerComponent)
              }
            ]
          }
        ]
      },
      {
        path: 'reporting',
        loadComponent: () =>
          import(
            './app/features/reporting/container/reporting-container.component'
          ).then((c) => c.AxleReportingContainerComponent)
      },
      {
        path: 'meeting',
        loadComponent: () =>
          import(
            './app/features/upload-meetings/edit-container/edit-meetings-container.component'
          ).then((c) => c.AxleEditMeetingsComponent)
      },
      {
        path: 'uploadMeetings',
        loadComponent: () =>
          import(
            './app/features/upload-meetings/upload-container/upload-meetings-container.component'
          ).then((c) => c.AxleUploadMeetingsComponent)
      },
      {
        path: 'profile',
        loadComponent: () =>
          import(
            './app/features/profile/container/profile-container.component'
          ).then((c) => c.AxleProfileContainerComponent),
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'user'
          },
          {
            path: 'user',
            loadComponent: () =>
              import(
                './app/features/profile/user/axle-user-profile.component'
              ).then((c) => c.AxleUserProfileComponent)
          },
          {
            path: 'org',
            loadComponent: () =>
              import(
                './app/features/profile/org/axle-org-profile.component'
              ).then((c) => c.AxleOrgProfileComponent)
          }
        ]
      },
      {
        path: 'preferences',
        loadComponent: () =>
          import(
            './app/features/preferences/preferences-container/preferences-container.component'
          ).then((c) => c.AxlePreferencesContainerComponent)
      },
      {
        path: '**',
        pathMatch: 'full',
        children: [],
        canActivate: [determineRedirectTo]
      }
    ],
    canActivate: [parentAuthGuardFn]
  },
  {
    path: '**',
    pathMatch: 'full',
    children: [],
    canActivate: [determineRedirectTo]
  }
];

init({
  dsn: 'https://4ad21a6729c420b2d3cac55ebf45161c@o4506972498952192.ingest.us.sentry.io/4506972507996160',
  integrations: [browserTracingIntegration()], //, replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://127.0.0.1:8443',
    'api-dev.axleaccess.com',
    'axle-qa.herokuapp.com',
    'api.axleaccess.com'
  ]
  // Session Replay
  // replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

window.Intercom('boot', {
  api_base: 'https://api-iam.intercom.io',
  app_id: 'ixtfpdrt'
});

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    provideRouter(routes, withViewTransitions(), withComponentInputBinding()),
    provideTransloco({
      config: {
        availableLangs: [
          {
            id: 'en',
            label: 'English'
          },
          { id: 'es', label: 'Spanish' },
          { id: 'fr', label: 'French' }
        ],
        defaultLang: 'en',
        reRenderOnLangChange: true
        // prodMode: environment.production
      },
      loader: TranslocoHttpLoader
    }),
    provideAuth0(environment.auth0),
    provideHttpClient(
      withInterceptors([authHttpInterceptorFn, whitelistInterceptorFn])
    ),
    // provideExperimentalZonelessChangeDetection(),
    // provideExperimentalCheckNoChangesForDebug({
    //   interval: 1000, // run change detection every second
    //   useNgZoneOnStable: true, // run it when the NgZone is stable as well
    //   exhaustive: true // check all components
    // }),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeGoogleMaps,
      deps: [GoogleMapsLoaderService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false
      })
    }
  ]
}).then((module) =>
  enableDebugTools(module.injector.get(ApplicationRef).components[0])
);
