import { UserService } from '../services/user/user.service';
import { UserStore } from '../services/user/user.state';
import { SecurityContext, inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthGuard, AuthService } from '@auth0/auth0-angular';
import { differenceInMinutes } from 'date-fns';
import posthog from 'posthog-js';
import { catchError, map, of, switchMap, take, tap } from 'rxjs';

export const parentAuthGuardFn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const authGuard = inject(AuthGuard);
  const sanitizer = inject(DomSanitizer);
  const userStore = inject(UserStore);
  const userService = inject(UserService);
  const userInfo = toObservable(userStore.userInfo);

  return inject(AuthService).isAuthenticated$.pipe(
    switchMap((authenticated) => {
      if (!authenticated) {
        throw new Error('unauthenticated');
      }
      return userInfo;
    }),
    switchMap((userInfo) => {
      if (
        userInfo?.timestamp &&
        differenceInMinutes(new Date(), userInfo.timestamp) < 5
      ) {
        return of(true);
      }

      return userService.getUserInfo$().pipe(
        tap((userInfoResponse) => {
          userStore.setInitialStore(userInfoResponse);
          posthog.identify(userInfoResponse.id);
          posthog.reloadFeatureFlags();

          window.Intercom('update', {
            api_base: 'https://api-iam.intercom.io',
            app_id: 'ixtfpdrt',
            email: userInfoResponse.email,
            user_id: userInfoResponse.id,
            created_at: new Date().getTime()
          });
        })
      );
    }),
    switchMap(() => authGuard.canActivate(route, state)),
    catchError(() => {
      const returnUrl = window.location.href;
      const url = sanitizer.sanitize(SecurityContext.URL, returnUrl);

      if (url) {
        const splitUrl = extractAfterThirdSlash(url);
        sessionStorage.setItem('returnUrl', splitUrl);
      }

      router.navigate(['login']);
      return of(true);
    }),
    take(1)
  );
};

function extractAfterThirdSlash(url: string) {
  const parts = url.split('/');

  // Check if there are at least four parts (which means at least three slashes)
  if (parts.length < 4) {
    throw new Error('URL does not contain three slashes.');
  }

  // Join the parts back together, starting from the fourth element (index 3)
  return parts.slice(3).join('/');
}

export const determineAuthRedirectTo: CanActivateFn = () => {
  const router = inject(Router);
  const auth0Service = inject(AuthService);

  return auth0Service.isAuthenticated$.pipe(
    tap((isAuthenticated) => {
      if (!isAuthenticated) {
        router.navigate(['login'], { replaceUrl: true });
      } else {
        router.navigate(['home']);
      }
    }),
    map(() => true),
    take(1)
  );
};

export const determineRedirectTo: CanActivateFn = () => {
  const router = inject(Router);
  const auth0Service = inject(AuthService);

  return auth0Service.isAuthenticated$.pipe(
    tap((isAuthenticated) => {
      if (!isAuthenticated) {
        router.navigate(['login']);
      } else {
        router.navigate(['home']);
      }
    }),
    map(() => true),
    take(1)
  );
};

export const isAdmin: CanActivateFn = () => {
  const router = inject(Router);
  const userStore = inject(UserStore);
  const auth0Service = inject(AuthService);

  return auth0Service.isAuthenticated$.pipe(
    map((isAuthenticated) => {
      const admin =
        isAuthenticated && !!userStore.userInfo()?.roleTypes.includes('ADMIN');
      if (!admin) {
        router.navigate([isAuthenticated ? 'home' : 'login']);
      }

      return admin;
    }),
    take(1)
  );
};
