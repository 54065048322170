/* eslint-disable @typescript-eslint/no-explicit-any */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { signal } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsLoaderService {
  isLoaded = signal(false);

  constructor(@Inject(DOCUMENT) private document: Document) {}

  loadScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isLoaded()) {
        resolve();
        return;
      }

      const script = this.document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googlePlacesKey}&libraries=places&loading=async`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        this.isLoaded.set(true);
        resolve();
      };

      script.onerror = (error: any) => reject(error);

      this.document.head.appendChild(script);
    });
  }
}
