import { isPlatformServer } from '@angular/common';
import { PLATFORM_ID, Signal, effect, inject, signal } from '@angular/core';
import {
  SignalStoreFeature,
  patchState as originalPatchState
} from '@ngrx/signals';

import { SignalStoreFeatureResult } from '@ngrx/signals/src/signal-store-models';
import { environment } from 'src/environments/environment';

export type Action = { type: string; value?: unknown };

const storeRegistry = signal<Record<string, Signal<unknown>>>({});
const currentName = signal<string | null>(null);

let currentActionNames = new Set<string>();

let synchronizationInitialized = false;

function initSynchronization() {
  effect(() => {
    if (!connection) {
      return;
    }

    const stores = storeRegistry();
    const rootState: Record<string, unknown> = {};
    for (const name in stores) {
      const store = stores[name];
      rootState[name] = store();
    }

    const type = currentName() ?? 'Store Update';
    currentActionNames = new Set<string>();

    const currName = currentName();

    connection.send(
      { type, value: currName ? rootState[currName] : undefined },
      rootState
    );
  });
}

function getValueFromSymbol(obj: unknown, symbol: symbol) {
  if (typeof obj === 'object' && obj && symbol in obj) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (obj as { [key: symbol]: any })[symbol];
  }
}

function getStoreSignal(store: unknown): Signal<unknown> {
  const [signalStateKey] = Object.getOwnPropertySymbols(store);
  if (!signalStateKey) {
    throw new Error('Cannot find State Signal');
  }

  return getValueFromSymbol(store, signalStateKey);
}

type ConnectResponse = {
  send: (action: Action, state: Record<string, unknown>) => void;
};
let connection: ConnectResponse | undefined;

/**
 * Used primarily on logout
 */
export function reset() {
  connection = undefined;
  synchronizationInitialized = false;
  storeRegistry.set({});
}

/**
 * @param name store's name as it should appear in the DevTools
 */
export function withDevtools<Input extends SignalStoreFeatureResult>(
  name: string
): SignalStoreFeature<Input, SignalStoreFeatureResult> {
  return (store) => {
    if (environment.env === 'prod') {
      return store;
    }

    const isServer = isPlatformServer(inject(PLATFORM_ID));
    if (isServer) {
      return store;
    }

    const extensions = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (!extensions) {
      return store;
    }

    if (!connection) {
      connection = extensions.connect({
        name: 'NgRx Signal Store'
      });
    }

    const storeSignal = getStoreSignal(store);
    currentName.set(name);

    storeRegistry.update((value) => ({
      ...value,
      [name]: storeSignal
    }));

    if (!synchronizationInitialized) {
      initSynchronization();
      synchronizationInitialized = true;
    }

    return store;
  };
}

type PatchFn = typeof originalPatchState extends (
  arg1: infer First,
  ...args: infer Rest
) => infer Returner
  ? (state: First, action: string, ...rest: Rest) => Returner
  : never;

export const patchState: PatchFn = (state, action, ...rest) => {
  currentActionNames.add(action);
  return originalPatchState(state, ...rest);
};
