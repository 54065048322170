export const defaultRequestOptions = {
  headers: {
    Accept: 'application/json; v=2'
  }
};

export const jsonDefaultRequestOptions = {
  headers: {
    Accept: 'application/json; v=2',
    'Content-Type': 'application/json'
  }
};

export const textDefaultRequestOptions = {
  headers: {
    Accept: 'text/plain',
    'Content-Type': 'application/json'
  },
  responseType: 'text' as 'json'
};
