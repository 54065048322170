import { axleStringCompare } from '../../functions/string.function';
import { FeatureFlag, PossibleAttendee, UserInfo } from './user.models';
import { Signal, computed } from '@angular/core';
import posthog from 'posthog-js';

import { appVersion } from 'src/app/version';
import { environment } from 'src/environments/environment';

export const sortedPossibleAttendees = (
  possibleAttendees: Signal<PossibleAttendee[]>
): Signal<PossibleAttendee[]> => {
  return computed(() => {
    const attendees = possibleAttendees();
    return attendees.sort((a, b) =>
      axleStringCompare(
        `${a.firstName} ${a.lastName}`,
        `${b.firstName} ${b.lastName}`
      )
    );
  });
};

export const sortedPossibleHosts = (
  possibleAttendees: Signal<PossibleAttendee[]>
): Signal<PossibleAttendee[]> => {
  return computed(() => {
    const attendees = possibleAttendees();
    return attendees
      .filter(({ google, outlook }) => google || outlook)
      .sort((a, b) =>
        axleStringCompare(
          `${a.firstName} ${a.lastName}`,
          `${b.firstName} ${b.lastName}`
        )
      );
  });
};

export const sortedFullPossibleAttendees = (
  possibleAttendees: Signal<PossibleAttendee[]>
): Signal<PossibleAttendee[]> => {
  return computed(() => {
    const attendees = possibleAttendees();
    return attendees
      .filter(({ isPartial }) => !isPartial)
      .sort((a, b) =>
        axleStringCompare(
          `${a.lastName}${a.firstName}`,
          `${b.lastName}${b.firstName}`
        )
      );
  });
};

export const showRefreshMessage = (
  enabledFeatureFlags: Signal<Set<string>>
): Signal<boolean> => {
  return computed(() => {
    enabledFeatureFlags();
    const minVersionFlag: FeatureFlag =
      environment.env === 'prod'
        ? 'axle_min_version_prod'
        : environment.env === 'staging'
          ? 'axle_min_version_testing'
          : 'axle_min_version_dev';
    const payload = posthog.getFeatureFlagPayload(minVersionFlag) as
      | { version: string }
      | undefined;

    if (!payload) return false;

    return appVersion.localeCompare(payload.version) === -1;
  });
};

export const isClientPortal = (
  enabledFeatureFlags: Signal<Set<string>>,
  userInfo: Signal<UserInfo | null>
) => {
  return computed(() => {
    enabledFeatureFlags();
    const orgId = userInfo()?.organization.id;
    if (!orgId) return false;

    const orgIds = posthog.getFeatureFlagPayload('axle_client_portal') as
      | string[]
      | undefined;

    if (!orgIds?.length) return false;

    return new Set(orgIds).has(orgId);
  });
};
