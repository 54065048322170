export const axleStringCompare = (a: string, b: string): number =>
  a
    .replace(/\s/g, '')
    .toLowerCase()
    .localeCompare(b.replace(/\s/g, '').toLowerCase());

export const axleTrimmedStringIncludes = (a: string, b: string): boolean =>
  a
    .replace(/\s/g, '')
    .toLowerCase()
    .includes(b.replace(/\s/g, '').toLowerCase());

export const axleTrimmedStringUnderscoreIncludes = (
  a: string,
  b: string
): boolean =>
  a
    .replace(/[\s_]/g, '')
    .toLowerCase()
    .includes(b.replace(/[\s_]/g, '').toLowerCase());

export const axleStringEq = (a: string, b: string): boolean => {
  return a.toLowerCase() === b.toLowerCase();
};

export const axleStringIncludes = (a: string, b: string): boolean => {
  return a.toLowerCase().includes(b.toLowerCase());
};

export const axleCapitalizeFirstLetter = (a: string): string => {
  if (!a) {
    return '';
  }

  return a.charAt(0).toUpperCase() + a.slice(1).toLowerCase();
};

export const getInitials = (fullName: string): string => {
  return fullName
    .split(' ')
    .map((name) => name.charAt(0).toUpperCase())
    .join('');
};

export const stringFn = (val: string) => val;
