import { Dialog } from '@angular/cdk/dialog';

import {
  AppState,
  AuthService,
  RedirectLoginOptions
} from '@auth0/auth0-angular';
import { tapResponse } from '@ngrx/operators';
import { WritableStateSource, patchState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, take } from 'rxjs';

import { environment } from 'src/environments/environment';

import { reset } from '../../functions/redux-dev-tools';
import { AuthenticationState } from './authentication.state';

export function login(
  auth0Service: AuthService,
  store: WritableStateSource<AuthenticationState>
) {
  return rxMethod<void>(
    pipe(
      switchMap(() => {
        const loginOptions: RedirectLoginOptions<AppState> = {
          authorizationParams: {
            screen_hint: 'login',
            prompt: 'login'
            // organization: 'org_0nV3K5uVB3nr70RW'
          },
          appState: {
            axleFlow: 'login',
            target: 'auth'
          }
        };

        return auth0Service.loginWithRedirect(loginOptions).pipe(take(1));
      }),
      tapResponse({
        next: () =>
          patchState(store, {
            loggingIn: false
          }),
        error: () =>
          patchState(store, {
            loggingIn: false
          })
      })
    )
  );
}

export function logout(
  auth0Service: AuthService,
  dialog: Dialog,
  store: WritableStateSource<AuthenticationState>
) {
  return rxMethod<void>(
    pipe(
      switchMap(() => {
        dialog.closeAll();
        reset();
        window.Intercom('shutdown');
        window.Intercom('boot');
        sessionStorage.removeItem('axle_session_token');

        return auth0Service
          .logout({
            logoutParams: {
              returnTo: `${environment.auth0.authorizationParams?.redirect_uri}/login`
            }
          })
          .pipe(take(1));
      }),
      tapResponse({
        next: () =>
          patchState(store, {
            loggingOut: false
          }),
        error: () =>
          patchState(store, {
            loggingOut: false
          })
      })
    )
  );
}

export function authenticationStoreMethods(
  auth0Service: AuthService,
  dialog: Dialog,
  store: WritableStateSource<AuthenticationState>
) {
  return {
    login: login(auth0Service, store),
    logout: logout(auth0Service, dialog, store)
  };
}
