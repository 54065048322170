import { computed } from '@angular/core';

import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState
} from '@ngrx/signals';

import { defaultImageUrls } from '../../contants/image.constants';
import { withDevtools } from '../../functions/redux-dev-tools';
import { Theme, themes } from '../../models/theme.models';

export interface ThemeState {
  currentTheme: Theme;
  darkMode: boolean;
}

export const initialState: ThemeState = {
  currentTheme: 'AXLE',
  darkMode: false
};

export const ThemeStore = signalStore(
  { providedIn: 'root' },
  withDevtools('theme'),
  withState(initialState),
  withMethods((store) => ({
    updateTheme: (currentTheme: Theme) => patchState(store, { currentTheme }),
    updateDarkMode: (darkMode: boolean) => patchState(store, { darkMode })
  })),
  withComputed(({ currentTheme, darkMode }) => ({
    theme: computed(() => themes[currentTheme()]),
    homeLogo: computed(() => {
      const useDarkMode =
        darkMode() || ['ASBURY', 'CLEAR_STREET'].includes(currentTheme());
      return useDarkMode
        ? defaultImageUrls.darkModeHomeLogo
        : defaultImageUrls.lightModeHomeLogo;
    }),
    loginLogo: computed(() => {
      const useDarkMode =
        darkMode() || ['ASBURY', 'CLEAR_STREET'].includes(currentTheme());
      return useDarkMode
        ? defaultImageUrls.darkModeLoginLogo
        : defaultImageUrls.lightModeLoginLogo;
    })
  }))
);
